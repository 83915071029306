import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ImageSlider from "../../components/ImageSlider";
import StarRating from "../../components/StarRating";
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS
import { toast } from 'react-toastify'; // Import toast from react-toastify
import { ProductFooter } from "../../components/ProductFooter";
import Loading from "../../components/loadingPulse/Loading";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai"; 
const apiBase =process.env.REACT_APP_API


const Item = ({ setCartItemCount }) => {


  const { product_id } = useParams();
  const [item, setItem] = useState([]);
  const [itemImg, setItemImg] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [isInCart, setIsInCart] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const res = await axios.get(`${apiBase}/item/${product_id}`);
        setItem(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchItem();
  }, [product_id]);

  useEffect(() => {
    const fetchItemImg = async () => {
      try {
        const res = await axios.get(
          `${apiBase}/item/img/${product_id}`
        );
        setItemImg(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchItemImg();
  }, [product_id]);

  useEffect(() => {
    const fetchSubProducts = async () => {
      try {
        const res = await axios.get(
          `${apiBase}/item/subproducts/${product_id}`
        );
        setSubProducts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchSubProducts();
  }, [product_id]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const res = await axios.get(
          `${apiBase}/item/rating/${product_id}`
        );
        setReviews(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchReviews();
  }, [product_id]);

  // chnage tab title
  useEffect(()=>{
    if(item[0])
    document.title = item[0].title;
  },[item])

  const { original_price, discount_price, description } = item?.[0] || {};
  const calculateDiscountPercentage = (original, discount) => {
    if (original && discount) {
      return Math.round(((original - discount) / original) * 100);
    }
    return 0;
  };

  const discountPercentage = calculateDiscountPercentage(
    original_price,
    discount_price
  );

  useEffect(() => {
    if (subProducts.length > 0) {
      const firstCategory = subProducts[0].sub_product_id;
      const firstColor = subProducts[0].color
        .split(",")
        .map((color) => color.trim())[0];
      setSelectedCat(firstCategory);
      setSelectedColor(firstColor);
    }
  }, [subProducts]);

  const handleCatClick = (catId) => {
    setSelectedCat(catId);
    setSelectedColor(null);
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  // Filter colors based on the selected category
  const filteredColors = selectedCat
    ? subProducts
        .filter((cat) => cat.sub_product_id === selectedCat)
        .flatMap((cat) => cat.color.split(",").map((color) => color.trim()))
        .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
    : [];

  //cart

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    if (cartItems.some((e) => e.sub_product_id === selectedCat)) {
      setIsInCart(true);
    } else {
      setIsInCart(false); // Reset the state if the item is not in the cart
    }
  }, [selectedCat]);

  const handleCartClick = (product_id, sub_product_id, color, quantity) => {
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const cartItem = {
      product_id,
      sub_product_id,
      color,
      quantity,
    };

    const itemExists = cartItems.some(
      (item) => item.sub_product_id === sub_product_id
    );

    if (isInCart || itemExists) {
      
      cartItems = cartItems.filter(
        (item) => !(item.sub_product_id === sub_product_id)
      );
      setIsInCart(false);

      setCartItemCount((c) => c - 1);

    } else {
      if (color == null) {
        
      toast.error("Select color first", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
       
        });
      } else {
        cartItems.push(cartItem);
        setIsInCart(true);

        setCartItemCount((c) => c + 1);

      }
    }
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  };

  //buy now
  const handleBuyNowClick = (product_id, sub_product_id, color, quantity) => {
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const cartItem = {
      product_id,
      sub_product_id,
      color,
      quantity,
    };

    const itemExists = cartItems.some(
      (item) => item.sub_product_id === sub_product_id
    );

    if (isInCart || itemExists) {
        navigate("/cart");
    } else {
      if (color == null) {
        toast.error("Select color first", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
         
          });
      } else {
        cartItems.push(cartItem);
        setIsInCart(true);
        setCartItemCount((c) => c + 1);
        navigate("/cart");
      }
    }
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  };


    // Wishlist functionality
    useEffect(() => {
      const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      const isItemInWishlist = wishlist.some((item) => item.product_id === product_id);
      setIsInWishlist(isItemInWishlist);
    }, [product_id, itemImg, item]);
    

    const handleWishlistClick = () => {
      let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    
      if (isInWishlist) {
        // Remove from wishlist
        wishlist = wishlist.filter((item) => item.product_id !== product_id);
        setIsInWishlist(false);
      } else {
        // Add to wishlist
        wishlist.push({
          product_id,
          photo_url: itemImg[0]?.url,  // Ensure itemImg exists before accessing it
          title: item[0]?.title,       // Ensure item exists before accessing it
          original_price,
          discount_price,
        });
        setIsInWishlist(true);
      }
    
      // Update the wishlist in localStorage
      localStorage.setItem("wishlist", JSON.stringify(wishlist));
    };
    


  return (
    <div className="flex justify-center  pt-32 md:pt-48 items-center">
      <div className="max-w-screen-l  lg:w-[1024px] px-8 border">
        <div className="flex flex-col mg:flex-row justify-start md:flex-row">
          <div>
            <ImageSlider p_array={itemImg} />
          </div>
          <div className=" w-full flex flex-col items-start md:pl-8 space-y-2">
            <h1 className="font-semibold text-3xl text-left">
              {item.length > 0 ? item[0].title : <Loading/>}
            </h1>
            <div className="flex flex-row items-center space-x-3">
              <div className="">

                <StarRating rating={reviews.star_count} />

              </div>
              <p className=" translate-y-0.5 underline font-semibold text-base text-myDim">
                {/*reviews ? reviews.reviews.length : "no reviews"*/}
              </p>
            </div>
            <div className="flex flex-col items-start font-semibold">
              <div className="flex flex-row items-center space-x-4">
                <h2 className="text-2xl text-myDim">Rs.{discount_price}.00</h2>
                {original_price && (
                  <p className="text-lg text-myRed">
                    {discountPercentage}% Off
                  </p>
                )}
              </div>
              {original_price && (
                <p className="text-xl line-through text-myRed text-opacity-50">
                  Rs.{original_price}.00
                </p>
              )}
            </div>
            <hr className="w-full mx-3 bg-myDim py-[1px] opacity-25" />

            {/* Category Selection */}
            <div className="flex flex-col items-start mb-4">
              <h3 className="font-semibold text-myDark">Category :</h3>
              <div className="flex flex-row justify-center flex-wrap">
                {subProducts.map((cat) => (
                  <button
                    key={cat.sub_product_id}
                    onClick={() => handleCatClick(cat.sub_product_id)}
                    className={`rounded-full text-sm font-semibold py-4 px-3 max-w-48 m-2 ${
                      selectedCat === cat.sub_product_id
                        ? "bg-myDim text-myLight"
                        : "bg-myLight border ring-1 ring-myDim text-myDim"
                    }`}
                  >
                    {cat.cat_1}
                  </button>
                ))}
              </div>
            </div>

            {/* Color Selection */}
            <div className="flex flex-col items-start">
              <h3 className="font-semibold text-myDark">Color :</h3>
              <div className="flex flex-row justify-center flex-wrap">
                {filteredColors.map((color) => (
                  <button
                    key={color}
                    onClick={() => handleColorClick(color)}
                    className={`rounded-3xl text-sm font-semibold py-2 px-3 m-2 ${
                      selectedColor === color
                        ? "bg-myDim text-myLight"
                        : "bg-myLight border ring-1 ring-myDim text-myDim"
                    }`}
                  >
                    {color}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <h3 className="font-semibold text-myDark text-left py-2">Quantity:</h3>
              <div>
                <input
                  className="bg-myLight border border-myDim rounded-sm w-20 p-2 px-3 m-2 text-myDim focus:outline-none focus:ring-myDim text-center shadow-sm transition duration-200 ease-in-out"
                  type="number"
                  value={selectedQuantity}

                  onChange={(e) => setSelectedQuantity(e.target.value)}
                  min="1"

                />
              </div>
            </div>
            <hr className="w-full mx-3 bg-myDim py-[1px] opacity-25" />
{/* Add to Wishlist Button */}
{/* <button
              onClick={handleWishlistClick}
              className="flex justify-center items-center py-2 px-4 bg-myDim text-white w-full rounded-lg hover:bg-opacity-80 transition"
            >
              {isInWishlist ? (
                <AiFillHeart className="text-red-600 mr-2" />
              ) : (
                <AiOutlineHeart className="text-white mr-2" />
              )}
              {isInWishlist ? "Remove from Wishlist" : "Add to Wishlist"}
            </button> */}
            {/* Displaying selected category and color */}
            {selectedCat && (
              <div className="mt-4 text-xs sm:text-sm font-normal bg-myDim bg-opacity-5 border rounded-lg p-2 text-myDim flex flex-col items-end w-full">
                {/* <h4 className="font-semibold text-myDark">Selected Category:</h4> */}
                <p className="text-right">
                  Selected Category:{" "}
                  {
                    subProducts.find(
                      (cat) => cat.sub_product_id === selectedCat
                    )?.cat_1
                  }
                </p>

                {/* <h4 className="font-semibold text-myDark mt-2">Selected Color:</h4> */}
                <p>Selected Color: {selectedColor}</p>
              </div>
            )}
            {/* <hr className="w-full mx-3 bg-myDim py-[1px] opacity-25" /> */}
            <div className="flex flex-row justify-end w-full">
              <button
                onClick={() =>
                  handleBuyNowClick(
                    item[0].product_id,
                    selectedCat,
                    selectedColor,
                    selectedQuantity
                  )
                }
                className="text-sm font-semibold bg-myRed text-myLight h-10 w-24 rounded-full hover:bg-opacity-85 m-2"
              >
                Buy now
              </button>
              <button
                onClick={() =>
                  handleCartClick(
                    item[0].product_id,
                    selectedCat,
                    selectedColor,
                    selectedQuantity
                  )
                }
                className="text-sm font-semibold bg-myDim bg-opacity-50 text-myLight h-10 w-24 rounded-full hover:bg-opacity-65 m-2"
              >
                {isInCart ? "Remove" : "Add to cart"}
              </button>
            </div>
          </div>
        </div>

        <ProductFooter description={description} reviewsObj={reviews} />
      </div>
    </div>
  );
};

export default Item;
