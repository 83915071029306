import React, { useState, useEffect } from "react";
import ItemCard from "../../components/ItemCard";

const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);

  useEffect(() => {
    const savedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    setWishlistItems(savedWishlist);
  }, []);

  const handleWishlistToggle = (item) => {
    const updatedWishlist = wishlistItems.filter(
      (wishlistItem) => wishlistItem.product_id !== item.product_id
    );
    setWishlistItems(updatedWishlist);
    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
  };

  return (
    <div className="max-w-screen-lg mx-auto p-4 pt-52">
      <h1 className="text-3xl font-bold mb-20">Wishlist</h1>

      <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 md:gap-8">
        {wishlistItems.length > 0 ? (
          wishlistItems.map((item) => (
            <ItemCard
              key={item.product_id}
              product_id={item.product_id}
              photo_url={item.photo_url}
              title={item.title}
              original_price={item.original_price}
              discount_price={item.discount_price}
              isInWishlist={true}  
              onWishlistToggle={() => handleWishlistToggle(item)}
            />
          ))
        ) : (
          <p className="text-myDim">No items in your wishlist.</p>
        )}
      </div>
    </div>
  );
};

export default Wishlist;
