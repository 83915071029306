import React, { useState } from 'react';
import FullStar from '../assets/FullStar.svg';
import HalfStar from '../assets/HalfStar.svg';
import EmptyStar from '../assets/EmptyStar.svg';
import './starRating.css';

const StarRating = ({ rating }) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (rating >= i) {

                stars.push(<img src={FullStar} alt="Full Star" className="size-4 sm:size-5 " key={i} />);

            } else if (rating >= i - 0.5) {
                stars.push(<img src={HalfStar} alt="Half Star" className=" size-4 sm:size-5" key={i} />);
            } else {
                stars.push(<img src={EmptyStar} alt="Empty Star" className="size-4 sm:size-5" key={i} />);
            }
        }
        return stars;
    };

    return (
        <div className="w-full flex flex-row items-center space-x-0 ">
            {renderStars()}
        </div>
    );
};

export default StarRating;