import React, { useState } from "react";
import {useNavigate} from 'react-router-dom'
const apiBase = process.env.REACT_APP_API;


const AddSubProduct = ({id, refreshSub}) => {

  const [subProductDetails, setSubProductDetails] = useState({
    cat_1: "",
    price: "",
    color: "",
  });
  const navigate = useNavigate()
  const handleChnage = (e) => {
    const { name, value } = e.target;

    setSubProductDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpload = async (e) => {
    try{
        e.preventDefault();
        const token = localStorage.getItem("token");
        if(!token) navigate("/login");

        const { cat_1, price, color } = subProductDetails;
  
      if (
        !cat_1 ||
        !price ||
        !color 
      ) {
        alert("set all fields");
        return;
      }
        const response = await fetch(`${apiBase}/admin/newsubproduct/${id}`,{
            method : "POST",
            headers : {
                Authorization: `${token}`,
                "Content-Type": "application/json",
            },
            body : JSON.stringify({
                cat_1,
                price,
                color
            })
        })
        if(response.ok){
            alert("sub product created successfully!!");
            setSubProductDetails({
                cat_1: "",
                price: "",
                color: "",
            })
            refreshSub();

        }
    }catch(error){
        alert("somthing went wrong");
    }
  }
  console.log(subProductDetails);
  return (
    <div className="  bg-white shadow-md rounded-t-lg lg:rounded-l-lg p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
        Add Sub Product
      </h2>
      <form onSubmit={handleUpload} className="space-y-4">
        <div>
          <label className="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2">
            Category
          </label>
          <input
            type="text"
            name="cat_1"
            className="appearance-none w-full  bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Enter Category"
            onChange={handleChnage}
            value={subProductDetails.cat_1}
          />
        </div>
        <div>
          <label className="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2">Price</label>
          <input
            type="number"
            name="price"
            className="appearance-none w-full  bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Enter Price"
            onChange={handleChnage}
            value={subProductDetails.price}
          />
        </div>
        <div>
          <label className="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2">Color</label>
          <input
            type="text"
            name="color"
            className="appearance-none w-full  bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Enter Color"
            onChange={handleChnage}
            value={subProductDetails.color}
          />
        </div>
        <button
          type="submit"
          className="w-full bg-myDim hover:bg-myDark hover:bg-opacity-65 text-white py-2 px-4 rounded-md  focus:outline-none focus:ring-none"
        >
          Add Sub Product
        </button>
      </form>
    </div>
  );
};

export default AddSubProduct;;