import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Loading from "../../components/loadingPulse/Loading";
const apiBase = process.env.REACT_APP_API;


const DashBoard = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashBoard = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiBase}/admin/dashboard`, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw response;
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        if (error.status == 401) {
          alert("unauthorized action!");
          navigate("/");
        }else{
          console.log(error)
        }
      }finally{
        setLoading(false);
      }
    };

    fetchDashBoard();
  }, []);

  return (
    <div className="pt-4 sm:ml-48  ">
      {loading && <Loading/>}
      {/* Display the fetched data */}
      {data && (
        <div>
          {/* <h1 className="text-3xl font-bold text-myBlue text-center pb-16">Dashboard Data</h1> */}
          <div className="bg-myDim p-10 rounded-2xl">
        <h1 className="text-center text-lg sm:text-3xl lg:text-4xl font-bold text-white max-w-screen-lg">
        Orders are customer purchases. Tracking them shows which products sell</h1>

          <div className="flex flex-row flex-wrap items-center justify-center mt-14 sm:mt-24 mb-16  ">
            <div className="max-w-screen-md grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 ">
            <p className="border border-2 rounded-lg p-4 font-bo flex flex-col items-center justify-center hover:bg-myDark hover:bg-opacity-10"><h1 className="text-3xl sm:text-6xl font-medium text-white ">{data.product_count}</h1><p className=" uppercase text-xs sm:text-sm text-white font-bold opacity-75">Products</p></p>
            <p className="border border-2 rounded-lg p-4 font-bo flex flex-col items-center justify-center hover:bg-myDark hover:bg-opacity-10"><h1 className="text-3xl sm:text-6xl font-medium text-white ">{data.sub_product_count}</h1><p className="uppercase text-xs sm:text-sm text-white font-bold opacity-75">Sub products</p></p>
            <p className="border border-2 rounded-lg p-4 font-bo flex flex-col items-center justify-center hover:bg-myDark hover:bg-opacity-10 "><h1 className="text-3xl sm:text-6xl font-medium text-white ">{data.user_count}</h1><p className=" uppercase text-xs sm:text-sm text-white font-bold opacity-75">Users</p></p>
            </div>

            <p className="text-myLight text-xs max-w-screen-md text-justify mt-8">Here, you’ll find everything you need to efficiently manage your medical tools and supplies. Our intuitive interface provides a comprehensive overview of your inventory, sales performance, and customer engagement. With real-time data insights, you can easily track trends, monitor orders, and optimize your product offerings to meet customer needs. Whether you're analyzing sales metrics or managing stock levels, our dashboard empowers you to make informed decisions that enhance your e-commerce experience. Dive in and discover how you can streamline your operations and boost your business potential with Meditool.lk!</p>
          </div>

          </div>
          
          
        </div>
      )}
    </div>
  );
};

export default DashBoard;
