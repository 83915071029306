/* global payhere */
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Loading from "../../components/loadingPulse/Loading";
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS
const apiBase = process.env.REACT_APP_API;

const CashOnDelivery = ({setCartItemCount}) => {
  const [customerDetails, setCustomerDetails] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    city: ""
  });

  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      alert("login first");
      navigate("/login");
    }
  }, [token, navigate]);

  const fetchCustomer = async () => {
    try {
      if (!token) {
        return;
      }
      const response = await fetch(`${apiBase}/profile`, {
        method: 'GET',
        headers: {
          Authorization: `${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Customer data:', data);

      setCustomerDetails({
        first_name: data.profile.first_name,
        last_name: data.profile.last_name,
        phone: data.profile.phone_number, // Correct key from the API response
        address: data.profile.address || "", // Ensure address is filled if available
        city: data.profile.city || "",
      });
    } catch (error) {
      console.error('Error fetching customer data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];
        const subProductIds = storedCart.map(item => item.sub_product_id);

        const response = await fetch(`${apiBase}/store/cart`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subIds: subProductIds })
        });

        const data = await response.json();
        if (response.ok) {
          const localData = JSON.parse(localStorage.getItem("cartItems")) || [];

          const updatedData = data.map(item => {
            const matchingLocalItem = localData.find(
              localItem => localItem.sub_product_id === item.sub_product_id
            );

            if (matchingLocalItem) {
              return {
                ...item,
                quantity: Number(matchingLocalItem.quantity),
                color: matchingLocalItem.color
              };
            }

            return item;
          });

          setCartItems(updatedData);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchItem();
  }, []);

  const calculateTotalPrice = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    const paymentDetails = {
      amount: `${calculateTotalPrice().toFixed(2)}`,
      cart: cartItems,
      first_name: customerDetails.first_name,
      last_name: customerDetails.last_name,
      address: customerDetails.address,
      phone: customerDetails.phone,
      city: customerDetails.city
    };

    try {
      const response = await fetch(`${apiBase}/pay/deliver/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        },
        body: JSON.stringify(paymentDetails),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
  });
        localStorage.removeItem("cartItems")
        setCartItemCount(0);
        navigate("/history")
      } else {
        
        // alert(error.error.message);
        alert(data.error.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handlePaymentDetailsChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };


  return (

    <div className="mt-40 flex justify-center items-center">
      {loading && <Loading/>}
      <div className="max-w-screen-lg px-6 grid grid-cols-1 md:grid-cols-3">
      <div className=" col-span-1 py-16 md:py-0 bg-myDim rounded-tl-lg rounded-tr-lg md:rounded-tr-none  md:rounded-bl-lg ">
        <div className=" flex flex-col items-center justify-center  h-full">
          
        <h1 className="flex justify-center items-center text-6xl text-myLight border size-24 rounded-full"><p>1</p></h1>
        <h4 className="text-white font-light text-2xl mt-4">Shipping details</h4>
            
          
        </div>
          
        </div>
        <form className=" col-span-2  p-6 bg-white shadow-md rounded-br-lg rounded-tr-none rounded-bl-lg  rounded-tr-lg md:rounded-tr-lg md:rounded-bl-none  space-y-4">
          <div className="grid grid-cols-2 gap-4">

          <div>
            <label
            className="block text-left text-myDark font-normal">First Name: </label>
            <input
              type="text"
              name="first_name"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="First name"
              value={customerDetails.first_name}
              onChange={handlePaymentDetailsChange}
            />
          </div>

          <div>
            <label className="block text-left text-myDark font-normal">Last Name: </label>
            <input
              type="text"
              name="last_name"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="Last name"
              value={customerDetails.last_name}
              onChange={handlePaymentDetailsChange}
            />
          </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>

            <label className="block text-left text-myDark font-normal">Phone Number: </label>

            <input
              type="text"
              name="phone"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="Phone number"
              value={customerDetails.phone}
              onChange={handlePaymentDetailsChange}
            />
          </div>
          </div>

          <div>

            <label className="block text-left text-myDark font-normal">Address: </label>

            <input
              type="text"
              name="address"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="Address"
              value={customerDetails.address}
              onChange={handlePaymentDetailsChange}
            />
          </div>

          <div>

            <label className="block text-left text-myDark font-normal">City: </label>

            <input
              type="text"
              name="city"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="City"
              value={customerDetails.city}
              onChange={handlePaymentDetailsChange}
            />
          </div>


          {/* Add more form fields for phone, address, etc. */}
          <div className=" flex justify-end pt-4">
      <button disabled={loading} id="payhere-payment" onClick={handlePayment}
      className="bg-myRed py-2 px-4 text-white font-semibold rounded-full">
        Deliver
      </button>
      </div>
        </form>
      </div>

    </div>
  );
};

export default CashOnDelivery;
