import React, { useState, useEffect } from "react";
import Loading from "../../components/loadingPulse/Loading";
import { Link } from "react-router-dom";

const apiBase = process.env.REACT_APP_API;

const History = () => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch payment history
  const fetchPaymentHistory = async () => {
    try {
      const token = localStorage.getItem("token");

      // If token is not available, do not proceed
      if (!token) {
        return;
      }

      // Fetch data from the profile endpoint
      const response = await fetch(`${apiBase}/profile`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();

      // Set the payment history state
      setPaymentHistory(data.payment || []); // Ensure it's an array
    } catch (error) {
      console.error("Error fetching payment history:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchPaymentHistory();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (paymentHistory.length === 0) {
    return (
      <div className="mt-44 flex flex-col items-center justify-center">
        <h2 className="text-3xl font-bold text-myDark mb-4">No Payment History Found</h2>
        <p className="text-lg text-gray-600">It looks like you haven't made any purchases yet.</p>
        <Link to={"/store"}>
        <button 
          className="mt-6 px-4 py-2 bg-myDim text-white rounded-lg hover:bg-opacity-80 transition duration-300"
        >
          Start Shopping
        </button>
        </Link>
      </div>
    );
  }
  

  return (
    <div className="mt-10 mx-auto max-w-4xl">
      <h2 className="text-3xl font-bold mt-36 mb-8 text-center text-gray-800">Payment History</h2>
      <div className="rounded-lg p-8">
        {paymentHistory.map((payment, index) => (
          <div
            key={index}
            className="mb-6 p-6 border-b-2 border-gray-200 bg-gray-50 rounded-lg"
          >
            <div className="flex justify-between mb-3">
              <h3 className="font-semibold text-lg">Order ID: {payment.order_id}</h3>
              <p className="text-sm text-gray-600">
                {new Date(payment.date).toLocaleString()}
              </p>
            </div>

            <div className="bg-white rounded-lg p-4 shadow-sm">
              <ul className="list-disc ml-6 space-y-1">
                {JSON.parse(payment.cart).map((item, idx) => (
                  <li key={idx} className="text-gray-700 list-none border-b border-t py-2">
                    <span className="font-medium">{item.title}</span> (x{item.quantity}) - 
                    <span className="font-medium"> Rs.{item.price * item.quantity}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-4 text-lg">
              <p>
                <strong>Total Price: </strong>
                <span className="font-semibold">Rs.{" "}
                  {JSON.parse(payment.cart).reduce(
                    (total, item) => total + item.price * item.quantity,
                    0
                  )}
                </span>
              </p>
            </div>

            <div className="mt-4">
              <p className={`text-md font-semibold 
                ${payment.status === 1 ? "text-green-600" : "text-yellow-600"}`}>
                Status: {payment.status === 1 ? "Online checkout" : "Cash on delivery"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
