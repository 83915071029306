/* global payhere */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loadingPulse/Loading";
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS
const apiBase = process.env.REACT_APP_API

const Payment = ({setCartItemCount}) => {
  const [customerDetails, setCustomerDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    country: "Sri Lanka",
    currency: "LKR" 
  });
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // getting customer data

  const token = localStorage.getItem("token");
  if(!token){
    toast.success("Loging first!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
});
    navigate("/login");
  }

  const fetchCustomer = async () => {
    try {
      
      if(!token){
        return
      }
      const response = await fetch(`${apiBase}/profile`, {
        method: 'GET',
        headers : {
          Authorization : `${token}`
        }
      });

      // Check if the response is OK (status code 200–299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);

      }
  
      const data = await response.json(); // Parse the JSON from the response
      console.log('Customer data:', data);
      // setCustomerDetails(data.profile);
      setCustomerDetails({
        first_name: data.profile.first_name,
        last_name: data.profile.last_name,
        email: "",
        phone_number: data.profile.phone_number,
        address: "",
        city: "",
        country: "Sri Lanka",
        currency: "LKR" 
      });
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  useEffect(
    ()=>{
      fetchCustomer();
    },[]
  )
  
  // calculating total price
  useEffect(() => {
    const fetchItem = async () => {
      try {
        // Retrieve and parse cart items from localStorage
        const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];
        const subProductIds = storedCart.map(item => item.sub_product_id); // Extract sub_product_id
  
        // Send sub_product_id array to the backend
        const response = await fetch(`${apiBase}/store/cart`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subIds: subProductIds })
        });
  
        const data = await response.json();
        if (response.ok) {
          // Parse localData from localStorage and ensure it's an array
          const localData = JSON.parse(localStorage.getItem("cartItems")) || [];
  
          // Map over the data received from the backend
          const updatedData = data.map(item => {
            // Find the corresponding item in localData based on sub_product_id
            const matchingLocalItem = localData.find(
              localItem => localItem.sub_product_id === item.sub_product_id
            );
  
            if (matchingLocalItem) {
              return {
                ...item,
                quantity: Number(matchingLocalItem.quantity), // Update quantity from localData
                color : matchingLocalItem.color
              };
            }
  
            return item; // Return original item if no match is found
          });
  
          // Log the merged data
          setCartItems(updatedData); // Set the updated data in state
        }
      } catch (err) {
        console.log(err);
      }finally{
        setLoading(false);
      }
    };
    
    fetchItem();
  }, []);

  const calculateTotalPrice = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  // ----------------------------
  
  
// handling payments
  const handlePayment = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token") ;
    console.log(`token : ${token}`);

    const paymentDetails = {
      amount: `${calculateTotalPrice().toFixed(2)}`,
      cart : cartItems,
      first_name : customerDetails.first_name,
      last_name : customerDetails.last_name,
      address : customerDetails.address,
      phone : customerDetails.phone_number,
      email : customerDetails.email,
      city : customerDetails.city


    };

    try {
      // Request backend to generate the hash value
      const response = await fetch(`${apiBase}/pay/start/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization : `${token}`
        },
        body: JSON.stringify(paymentDetails),
      });

      if (response.ok) {
        const { hash, merchant_id, order_id } = await response.json();

        payhere.onCompleted = function onCompleted() {
          localStorage.removeItem("cartItems")
          setCartItemCount(0);
          navigate("/history");
          
          
      };
  
        // Payment configuration
        const payment = {
          sandbox: true, // Use sandbox for testing
          merchant_id: merchant_id,
          return_url: "http://localhost:3000/payment/success", // Replace with your return URL
          cancel_url: "http://localhost:3000/payment/cancel", // Replace with your cancel URL
          notify_url: "https://carlton-j3yq.vercel.app/pay/notify/", // Replace with your notify URL
          order_id: order_id,
          items: "Online Checkout",
          amount: paymentDetails.amount,
          currency: customerDetails.currency,
          first_name: customerDetails.first_name,
          last_name: customerDetails.last_name,
          email: customerDetails.email,
          phone: customerDetails.phone_number,
          address: customerDetails.address,
          city: customerDetails.city,
          country: customerDetails.country,
          custom_1 : customerDetails.address,
          custom_2 : cartItems ,
          hash: hash,
        };

        // Check if payhere is available before starting payment
        if (typeof payhere !== "undefined") {
          payhere.startPayment(payment); // Start payment process
        } else {
          console.error("PayHere SDK not loaded");
        }
      } else {
        const error = await response.json();
      toast.error(error.error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
       
        });
      }
    } catch (error) {
      // console.error("An error occurred:", error);
    }
  };

  const handlePaymentDetailsChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // if(loading){
  //   return(<Loading/>)
  // }

  return (

    <div className="mt-40 flex justify-center items-center">
    {loading && <Loading/>}
      <div className="max-w-screen-lg px-6 grid grid-cols-1 md:grid-cols-3">
        <div className=" col-span-1 py-16 md:py-0 bg-myDim rounded-tl-lg rounded-tr-lg md:rounded-tr-none  md:rounded-bl-lg ">
        <div className=" flex flex-col items-center justify-center  h-full">
          
        <h1 className="flex justify-center items-center text-6xl text-myLight border size-24 rounded-full"><p>1</p></h1>
        <h4 className="text-white font-light text-2xl mt-4">Shipping details</h4>
            
          
        </div>
          
        </div>
        <form className=" col-span-2  p-6 bg-white shadow-md rounded-br-lg rounded-tr-none rounded-bl-lg  rounded-tr-lg md:rounded-tr-lg md:rounded-bl-none space-y-4">
          <div className="grid grid-cols-2 gap-4">

          <div>
            <label className="block text-left text-myDark font-normal">First Name: </label>
            <input
              type="text"
              name="first_name"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="First name"
              value={customerDetails.first_name}
              onChange={handlePaymentDetailsChange}
            />
          </div>

          <div>
            <label className="block text-left text-myDark font-normal">Last Name: </label>
            <input
              type="text"
              name="last_name"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="Last name"
              value={customerDetails.last_name}
              onChange={handlePaymentDetailsChange}
            />
          </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-left text-myDark font-normal">Email: </label>
            <input
              type="email"
              name="email"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="Email"
              value={customerDetails.email}
              onChange={handlePaymentDetailsChange}
            />
          </div>

          <div>
            <label className="block text-left text-myDark font-normal">Phone number: </label>
            <input
              type="text"
              name="phone_number"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="phone number"
              value={customerDetails.phone_number}
              onChange={handlePaymentDetailsChange}
            />
          </div>
          </div>
          <div>
            <label className="block text-left text-myDark font-normal">Address: </label>
            <input
              type="text"
              name="address"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="address"
              value={customerDetails.address}
              onChange={handlePaymentDetailsChange}
            />
          </div>

          <div>
            <label className="block text-left text-myDark font-normal">City: </label>
            <input
              type="text"
              name="city"
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-0 placeholder:opacity-50"
              placeholder="city"
              value={customerDetails.city}
              onChange={handlePaymentDetailsChange}
            />
          </div>
        <div className=" flex justify-end pt-4">
      <button disabled={loading} id="payhere-payment" onClick={handlePayment} className="bg-myRed py-2 px-4 text-white font-semibold rounded-full">
        Checkout
      </button>
      </div>
          {/* Add more form fields for phone, address, etc. */}
        </form>
      </div>
    </div>
  );
};

export default Payment;
