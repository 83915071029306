import React, { useEffect } from "react";
import AboutSideImages from "../components/AboutSideImages";
import Contact from "../components/Contact";
import iSamsung from "../assets/sponsors/samsung.png";
import iLittmann from "../assets/sponsors/littmann.png";
import Gallery from "../assets/gallery.png";

function About() {
  // chnage tab title
  useEffect(() => {
    document.title = "MEDtools.lk | About";
  }, []);
  return (
    <div className="mt-40 flex justify-center items-center ">
      <div className="px-6 lg:px-0 max-w-screen-lg">
        {/* ------------------------------Hero---------------------------------- */}
        <div className="grid space-y-8 md:space-y-0 grid-col-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col justify-center space-y-2">
            <h1 className="text-center text-5xl md:text-left font-semibold text-myDark ">
              Who we are?
            </h1>
            <p className="text-center md:text-left text-sm text-myDim">
              DOCTORhub.lk is a website that is owned and operated by two Sri Lankan
              medical students who have set their sights on providing their
              fellow students with affordable and high-quality medical
              equipment. The website's main objective is to offer original
              medical products to Sri Lankan medical students at a more
              affordable price than any other seller in the market.{" "}
            </p>
          </div>
          <div className=" flex justify-center items-center">
            <img src={Gallery} alt="" className="h-[500px] drop-shadow-lg" />
            {/* <AboutSideImages/> */}
          </div>
        </div>
        {/* ------------------------------/Hero---------------------------------- */}
        {/* ------------------------------Analytics---------------------------------- */}
        <div className="grid px-16 md:px-0 gap-8  grid-cols-1 md:grid-cols-2 mt-36">
          <div className=" py-8 md:py-0 space-y-8 ">
            <div className="flex flex-col items-center md:items-start">
              <h2 className="text-center text-center md:text-left font-semibold text-2xl text-myDark">
                Your Trusted Partner in Medical Solutions
              </h2>
              <p className=" text-center md:text-left text-xs md:text-sm text-myDim ">
                At [Your Business Name], we are dedicated to providing
                top-quality medical equipment to healthcare professionals around
                the world. With cutting-edge technology and a commitment to
                innovation, we ensure that your patients receive the best care
                possible. Our range of products is tailored to meet the evolving
                needs of the medical field.
              </p>
            </div>
            <div className="flex flex-col items-center md:items-start">
              <h2 className="text-center text-center md:text-left font-semibold text-2xl text-myDark">
                Reliable Equipment for Every Need
              </h2>
              <p className=" text-center md:text-left text-xs md:text-sm text-myDim ">
                From diagnostic tools to life-saving devices, our inventory
                includes everything you need to run an efficient medical
                practice or hospital. Our products are known for their
                durability, precision, and user-friendliness, helping healthcare
                professionals deliver better outcomes.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-16 md:space-y-6 md:flex md:flex-col md:items-center md:justify-center ">
            <div className="flex flex-col items-center">
              <h3 className="text-myDark text-4xl font-medium text-left">
                1500+
              </h3>
              <p className="text-myDim text-sm text-left">
                Satisfied Customers Worldwide
              </p>
            </div>
            <div className="flex flex-col items-center">
              <h3 className="text-myDark text-4xl font-medium text-left">
                2000+
              </h3>
              <p className="text-myDim text-sm text-left">
                Successful Transactions Globally
              </p>
            </div>
            <div className="flex flex-col items-center">
              <h3 className="text-myDark text-4xl font-medium text-left">
                50+
              </h3>
              <p className="text-myDim text-sm text-left">
                Healthcare Professionals Equipped
              </p>
            </div>
          </div>

          {/* ------------------------------/Analytics---------------------------------- */}
          {/* ------------------------------Brand---------------------------------- */}
        </div>
        <div className="w-full space-y-16 mt-36 flex flex-col items-center">
          <h1>Trusted By</h1>
          <div className="w-full flex flex-row justify-between px-16 md:px-28 py-9 shadow-md rounded-md border border-myDim border-opacity-30">
            <img src={iSamsung} alt="SAMSUNG-LOGO" className="h-10 md:h-20" />
            <img src={iLittmann} alt="LITTMANN-LOGO" className="h-10 md:h-20" />
          </div>
        </div>
        {/* ------------------------------/Brand---------------------------------- */}

        <div>
          <Contact />
        </div>
      </div>
    </div>
  );
}

export default About;
