import React from "react";
import { useNavigate } from "react-router-dom";
import { CgCloseR } from "react-icons/cg";

const SearchResultsPopup = ({ searchQuery, results, isLoading, onClose }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleNavigate = (product_id) => {
    navigate(`/item/${product_id}`); // Navigate to the product page
    onClose(); // Close the popup after navigation
  };

  return (
    <>
      {/* Update condition to check for either loading state or results */}
      {(isLoading || results.length > 0) && (
        <div className="absolute mx-4 md:mt-2 md:mx-0 left-0 right-0 bg-white rounded-lg md:rounded-lg shadow-lg max-h-96">
          {/* Close Button */}
          <div className="flex justify-between items-end md:items-center px-4 py-2">
            <p className="text-gray-600 text-sm flex flex-row justify-end md:justify-start w-full">
              <span></span>Search for: <span className="font-semibold">{searchQuery}</span>
            </p>
            <button onClick={onClose} className="hidden md:block text-red-500">
              <CgCloseR className="text-myRed size-6 opacity-75" />
            </button>
          </div>

          {/* Loading State */}
          {isLoading ? (
            <div className="flex justify-center items-center py-4">
              {/* Ensure your loader styling works here */}
              <div className="loader">Loading...</div>
            </div>
          ) : (
            <ul className="divide-y divide-gray-200  overflow-y-auto overflow-x-hidden z-50 mt-1 scrollbar-thin scrollbar-thumb-myDim  scrollbar-track-gray-50 scrollbar-thumb-rounded-full h-72 ">
              {/* Results Mapping */}
              {results.map((result) => (
                <li
                  key={result.product_id}
                  className="flex p-4 items-center cursor-pointer hover:bg-myDim hover:bg-opacity-5"
                  onClick={() => handleNavigate(result.product_id)} // Call handleNavigate on click
                >
                  {/* Product Image */}
                  <img
                    src={result.photo_url}
                    alt={result.title}
                    className="w-12 h-12 mr-4 object-cover"
                  />
                  <div className="flex flex-col items-start">
                    <h3 className="text-base text-myDark truncate font-semibold">
                      {result.title}
                    </h3>
                    {/* Price */}
                    <p className="text-myRed font-semibold">
                      Rs. {result.discount_price}.00
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default SearchResultsPopup;
