import { fromJSON } from 'postcss'
import React from 'react'
import './notFound.css';
import eyeImage from '../../assets/404pageEye.png';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div>
    <div className='flex flex-col justify-center items-center' >
      <div className='flex flex-col justify-center items-center _eye pt-40 '>
        <img src={eyeImage} alt="404_image" className=' ' />
        <h1>Oops! Page Not Found</h1>
        <h2>It seems like this page needs a check-up !</h2>
        <p className='px-12 sm:px-16 md:px-40 pt-1 pb-10'>We couldn't find the page you're looking for, but our team of medical experts is always here to help. If you need assistance, please visit our homepage or contact us directly.</p>
        <Link to="/">
        <button  className='_button _active_button'>Go Back Home</button>
        </Link>
      </div>
    </div>
    </div>
  )
}

export default NotFound