import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom"; // Import React Router hooks
import ItemCard from "../../components/ItemCard";
import "./Store.css";
import iNavLeft from "../../assets/i-nav-left.svg";
import iNavRight from "../../assets/i-nav-right.svg";
import Loading from "../../components/loadingPulse/Loading";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS

const apiBase = process.env.REACT_APP_API;
const itemsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE || 12;

function Store() {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("ASC");
  const [items, setItems] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [wishlistItems, setWishlistItems] = useState(() => {
    // Get wishlist from localStorage on initial load
    const savedWishlist = localStorage.getItem("wishlist");
    return savedWishlist ? JSON.parse(savedWishlist) : [];
  });

  // chnage tab title
  useEffect(() => {
    document.title = "MEDtools.lk | Store";
  }, []);

  // Mapping of user-friendly paths to internal category names
  const pathToCategory = {
    all: "all",
    tabs: "tab",
    tabaccessories: "tabAcc",
    stethaccessories: "stethAcc",
  };

  // Reverse mapping for changing the URL dynamically
  const categoryToPath = {
    all: "all",
    tab: "tabs",
    tabAcc: "tabaccessories",
    stethAcc: "stethaccessories",
  };

  // Get the user-friendly category from the URL
  const { category: pathCategory } = useParams();

  // Map the user-friendly URL to the internal category name (default to 'all')
  const category = pathToCategory[pathCategory] || "all";

  const navigate = useNavigate(); // For navigating to different paths

  // Mapping of internal categories to user-friendly names for display
  const categoryNames = {
    all: "All Products",
    tab: "Tabs",
    tabAcc: "Tab Accessories",
    stethAcc: "Steth Accessories",
  };

  // Wrap fetchProducts with useCallback to prevent redefinition
  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.post(`${apiBase}/store/`, {
        category: category, // Use internal category name for fetching
        sort: sort,
        page: page,
        itemsPerPage: itemsPerPage,
      });

      setItems(response.data.itemDetails);
      setItemCount(response.data.itemCount);
    } catch (err) {
      console.error("Failed to fetch products:", err);
    }
  }, [category, sort, page]);

  useEffect(() => {
    setItems([]);
    fetchProducts(); // Call the fetchProducts function
  }, [fetchProducts]); // Add fetchProducts as a dependency

  // Handle changing the category and updating the URL to user-friendly paths
  const handleCategoryChange = (newCategory) => {
    setPage(1); // Reset to the first page
    navigate(`/store/${categoryToPath[newCategory]}`); // Change the URL to user-friendly path
  };


  // Handle wishlist change
  const handleWishlistClick = (product) => {
    let updatedWishlist = [...wishlistItems];

    const existingItem = updatedWishlist.find(
      (item) => item.product_id === product.product_id
    );

    if (existingItem) {
      updatedWishlist = updatedWishlist.filter(
        (item) => item.product_id !== product.product_id
      );
    } else {
      updatedWishlist.push(product);
      toast.success("Product successfully added to your wishlist!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        
        });
      
    }

    setWishlistItems(updatedWishlist);
    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist)); // Save full product details
  };



  return (
    <div className="flex justify-center pt-40 md:pt-48">
      <div className="flex justify-between Tabs">
        {/* Left-Side Menu */}
        <div className="mt-28 pr-0 hidden md:block md:pr-3 border-r">
          <ul className="space-y-3  text-base font-semibold text-myDark">
            {/* All Products */}
            <li
              onClick={() => handleCategoryChange("all")} // Navigate to user-friendly path
              className={`flex flex-row text-left justify-start align-middle hover:bg-myDim hover:bg-opacity-30 rounded-sm ${
                category === "all" ? "bg-myDim bg-opacity-30" : ""
              }`}
            >
              <label className="flex flex-row items-center align-middle justify-start">
                <span className="ml-2">All</span>
              </label>
            </li>

            {/* Tabs */}
            <li
              onClick={() => handleCategoryChange("tab")} // Navigate to user-friendly path
              className={`flex flex-row text-left justify-start align-middle hover:bg-myDim hover:bg-opacity-30 rounded-sm ${
                category === "tab" ? "bg-myDim bg-opacity-30" : ""
              }`}
            >
              <label className="flex flex-row items-center align-middle justify-start">
                <span className="ml-2">Tabs</span>
              </label>
            </li>

            {/* Tab Accessories */}
            <li
              onClick={() => handleCategoryChange("tabAcc")} // Navigate to user-friendly path
              className={`flex flex-row text-left justify-start align-middle hover:bg-myDim hover:bg-opacity-30 rounded-sm ${
                category === "tabAcc" ? "bg-myDim bg-opacity-30" : ""
              }`}
            >
              <label className="flex flex-row items-center align-middle justify-start">
                <span className="ml-2">Tab Accessories</span>
              </label>
            </li>

            {/* Steth Accessories */}
            <li
              onClick={() => handleCategoryChange("stethAcc")} // Navigate to user-friendly path
              className={`flex flex-row text-left justify-start align-middle hover:bg-myDim hover:bg-opacity-30 rounded-sm ${
                category === "stethAcc" ? "bg-myDim bg-opacity-30" : ""
              }`}
            >
              <label className="flex flex-row items-center align-middle justify-start">
                <span className="ml-2">Steth Accessories</span>
              </label>
            </li>
          </ul>
        </div>

        {/* Right-Side Content */}
        <div className="w-full md:w-3/4 sm:p-4">
          <div className="flex flex-col md:flex-row justify-between items-start mb-4">
            <div className="flex flex-row justify-between items-center w-full">
              <div className="flex flex-col items-start">
                {/* Display the user-friendly category name */}
                <h2 className="_categories-type">
                  {categoryNames[category] || categoryNames["all"]}
                </h2>
                <p className="_categories-qnt text-left">
                  {itemCount} items found
                </p>
              </div>

              <div className="flex flex-row space-x-1 lg:space-x-4">
                <div className="flex items-center space-x-1 text-myDim bg-myLight">
                  <p className="hidden lg:block">Sort by:</p>
                  <select
                    className="border border-myDim lg:text-base text-sm w-24 h-10 md:h-12 rounded-full py-0 sm:py-2 sm:w-32 lg:w-36 focus:outline-none focus:ring-0 bg-myLight"
                    onChange={(e) => {
                      setSort(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="ASC">Price: Low to High</option>
                    <option value="DESC">Price: High to Low</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Category Options for Mobile */}
            <ul className="block md:hidden grid grid-cols-1 w-full p-4 gap-2 text-xs font-semibold text-myDark">
              <li
                onClick={() => handleCategoryChange("all")}
                className={`h-10 flex flex-row items-center justify-center border border-myDim border-opacity-30 hover:bg-myDim hover:bg-opacity-30 rounded-sm ${
                  category === "all" ? "bg-myDim bg-opacity-30" : ""
                }`}
              >
                All
              </li>
              <li
                onClick={() => handleCategoryChange("tab")}
                className={`h-10 flex flex-row items-center justify-center border border-myDim border-opacity-30 hover:bg-myDim hover:bg-opacity-30 rounded-sm ${
                  category === "tab" ? "bg-myDim bg-opacity-30" : ""
                }`}
              >
                Tabs
              </li>
              <li
                onClick={() => handleCategoryChange("tabAcc")}
                className={`h-10 flex flex-row items-center justify-center border border-myDim border-opacity-30 hover:bg-myDim hover:bg-opacity-30 rounded-sm ${
                  category === "tabAcc" ? "bg-myDim bg-opacity-30" : ""
                }`}
              >
                Tab Accessories
              </li>
              <li
                onClick={() => handleCategoryChange("stethAcc")}
                className={`h-10 flex flex-row items-center justify-center border border-myDim border-opacity-30 hover:bg-myDim hover:bg-opacity-30 rounded-sm ${
                  category === "stethAcc" ? "bg-myDim bg-opacity-30" : ""
                }`}
              >
                Steth Accessories
              </li>
            </ul>
          </div>

          {/* Display items */}

          <div className="flex justify-center items-center min-h-72">
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {items.length <= 0 ? (
                <Loading />
              ) : (
                items.map((item) => (
                  <div key={item.product_id}>
                    <ItemCard
                      product_id={item.product_id}
                      photo_url={item.photo_url}
                      title={item.title}
                      original_price={item.original_price}
                      discount_price={item.discount_price}
                      isInWishlist={wishlistItems.some(
                        (wishlistItem) =>
                          wishlistItem.product_id === item.product_id
                      )}
                      onWishlistToggle={() => handleWishlistClick(item)}
                    />
                    {/* Add to Wishlist Button */}
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Pagination */}
          <div className="_bottom-nav flex justify-end items-center mt-4 space-x-5  mb-2">
            <button
              className="size-10 flex justify-center items-center"
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))} // Decrement page number
              disabled={page === 1}
            >
              <img src={iNavLeft} alt="back" className="size-4" />
            </button>
            <button className="text-white  px-5 py-1 bg-myDim m-4 rounded-md">
              {page}
            </button>
            <button
              className="size-10 flex justify-center items-center"
              onClick={() => setPage((prev) => prev + 1)} // Increment page number
              disabled={
                Math.ceil(itemCount / process.env.REACT_APP_ITEMS_PER_PAGE) ==
                page
              } // Correctly setting the disabled attribute
            >
              <img src={iNavRight} alt="right" className="size-4" />
            </button>
          </div>
          <div>

            <h3 className="text-myDark font-semibold text-center  p-2">
              Page {page} of{" "}

              {Math.ceil(itemCount / process.env.REACT_APP_ITEMS_PER_PAGE)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Store;
