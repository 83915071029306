import React, { useState } from "react";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Dropdown, DropdownHeader } from "flowbite-react";

import DashBoard from "./DashBoard";
import AddProduct from "./AddProduct";
import NewProduct from "./NewProduct";
import EditProduct from "./EditProduct";
import { AllProducts } from "./AllProducts";
import  Orders  from "./Orders";

const AdminHome = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate()

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {/* Top Navbar */}
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex flex-row items-center justify-start">
              {/* Sidebar Toggle Button */}
              <button
                onClick={toggleSidebar}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  />
                </svg>
              </button>
              {/* Logo */}
              <Link to="/admin" className="flex items-center space-x-3 ml-3 sm:ml-0 sm:mb-2 sm:mb-0">
                <h1 className="self-center text-myBlack text-2xl font-normal">
                  <span className="font-bold">MEDi</span>tools.lk
                </h1>
              </Link>
            </div>
            <p className="font-thin hover:underline hidden sm:block text-base md:ml-48 text-gray-400">
              Welcome to the Admin Panel
            </p>
            <div className="flex items-center">
              {/* User Menu */}
              <Dropdown
                className="bg-white bg-opacity-75 backdrop-blur-sm px-4"
                arrowIcon={false}
                inline
                label={
                  <Avatar
                    className="px-4"
                    alt="User settings"
                    img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                    rounded
                  />
                }
              >
                <Dropdown.Header className="flex flex-col items-start">
                  <span className="block text-lg font-normal text-myDark">
                    Admin
                  </span>
                  <span className="block truncate opacity-50 text-base font-medium">
                    MEDtools.lk
                  </span>
                </Dropdown.Header>
                <Link to={"/"}>
                <Dropdown.Header className="flex flex-col items-start">
                  
                    <span className="block truncate opacity-50 text-base  font-medium">See Web</span>
                  
                </Dropdown.Header></Link>
                <Dropdown.Divider />
                <Dropdown.Item onClick={()=>{localStorage.removeItem("token"); navigate("/login") }} className="font-semibold text-base text-myDim">
                  Log out
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar */}
      
      <aside
        id="logo-sidebar"
        className={`fixed z-10 sm:z-0 top-0 left-0 w-64 h-full pt-20 transition-transform ${
          isSidebarOpen ? "" : "-translate-x-full"
        } bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto flex flex-col justify-between bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium flex flex-col pl-5">
            <li>
              <Link
                to="/admin/"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/admin/" ? "bg-gray-100" : ""
                }`}
              >
                <span className="ms-3 text-myBlue">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/admin/new/"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/admin/new/" ? "bg-gray-100" : ""
                }`}
              >
                <span className="ms-3 text-myBlue">Add Product</span>
              </Link>
            </li>
            <li>
              <Link
                to="products"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "products" ? "bg-gray-100" : ""
                }`}
              >
                <span className="ms-3 text-myBlue">All Products</span>
              </Link>
            </li>
            <li>
              <Link
                to="orders"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "products" ? "bg-gray-100" : ""
                }`}
              >
                <span className="ms-3 text-myBlue">See Orders</span>
              </Link>
            </li>
          </ul>
          <div className="mt-8 flex flex-col items-center bottom-0">
            <p className="text-xs px-4 text-justify text-myBlue py-3">
              This admin panel is developed and maintained by the devX team.
              Please review all updates and settings carefully before
              implementation. For assistance, contact the development team.
            </p>
            <div>
              <h2 className="text-sm font-semibold text-gray-600 text-justify px-4">
                "Not just a company, we're a team,"
              </h2>
              <a
                href="https://web.facebook.com/profile.php?id=61563620580575"
                target="_blank"
                className="mx-4 mt-2 p-1 text-sm font-semibold flex bg-gray-200 w-fit rounded-lg flex-row items-center opacity-75 hover:underline"
              >
                <p>devX team</p>
              </a>
            </div>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <div className="p-4 pt-20 w-full mainContent flex justify-center items-center">
        <div className="  sm:pl-16 w-full">
          <Routes>
            <Route path="" element={<DashBoard />} />
            <Route path='/new/' element={<NewProduct/>} />
            <Route path='/edit/:id' element={<EditProduct/>} />
            <Route path='/products' element={<AllProducts/>} />
            <Route path='/orders' element={<Orders/>} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
