import React from "react";
import "./faq.css";

const faqs = [
  {
    question: "How secure is my data on inbox?",
    answer:
      "Your data is highly secure with inbox. We use state-of-the-art encryption and security protocols to ensure your information is protected.",
  },
  {
    question: "Can I integrate inbox with my existing email accounts?",
    answer:
      "Yes, inbox can be integrated with your existing email accounts, allowing you to manage all your emails in one place.",
  },
  {
    question: "How does inbox help with email organization?",
    answer:
      "Inbox uses AI to automatically categorize and prioritize your emails, making it easier for you to manage your inbox.",
  },
  {
    question: "Will inbox work with multiple devices?",
    answer:
      "Yes, inbox is designed to work seamlessly across multiple devices, so you can access your emails from anywhere.",
  },
];
function Faq() {
  const [expandedFaq, setExpandedFaq] = React.useState(null);
  const toggleFaq = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <section className=" max-w-screen-lg px-4   mt-8 fade-in">
        <h2 className=" flex justify-center text-myDark text-2xl md:text-4xl">FAQs</h2>
        <p className="text-center mb-8 opacity-25">
          You've got questions, we've got answers.
        </p>
        <div className="grid grid-cols-1  gap-4">
          {faqs.map((faq, index) => (
            <div key={index} className="break-inside-avoid bg-white p-4 rounded-lg  shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFaq(index)}
              >
                <p className="text-myDark font-semibold">{faq.question}</p>
                <i
                  className={`fas fa-${
                    expandedFaq === index ? "minus" : "plus"
                  } text-myDim`}
                ></i>
              </div>
              <div
                className={`faq-item ${
                  expandedFaq === index ? "expanded" : "collapsed"
                }`}
              >
                <p className="mt-2 text-sm text-myDim text-justify">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Faq;
