import React, { useEffect, useState } from "react";
import "./contact.css";
import contactImage from "../assets/Contact-image.webp";
import iFb from "../assets/i-fb.svg";
import iWhatsapp from "../assets/i-whatsapp.svg";
const apiBase = process.env.REACT_APP_API;

function Contact() {
  useEffect(() => {
    document.title = "MEDtools.lk | Contact";
  }, []);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      const response = await fetch(`${apiBase}/email/send`,{
        method : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
    }catch(error){
      alert(error);
    }
  };
  
  return (
    <div id="contact" className="  flex justify-center items-center">
      <div className="max-w-screen-lg py-24 mt-2 flex justify-center items-center">
        <div className="mx-auto max-w-7xl px-3 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 grid-cols-1">
            {/* Left Side (Contact Image and Info) */}
            <div className="lg:mb-0 mb-">
              <div className="group w-full h-full">
                <div className="relative h-full">
                  <img
                    src={contactImage}
                    alt="Contact Us"
                    className=" h-full w-full rounded-t-lg md:rounded-r-none md:rounded-l-lg object-cover filter brightness-95"
                  />
                  <h1 className="font-manrope text-white drop-shadow-lg text-2xl sm:text-4xl font-bold leading-10 absolute top-4 md:top-11 left-6 md:left-11">
                    Contact us
                  </h1>
                  <div className="absolute bottom-0 w-full p-5 lg:p-11 ">
                    <div className="bg-white bg-opacity-75 backdrop-blur-sm hover:bg-opacity-80 rounded-lg p-3 sm:p-6 block">
                      <a href="javascript:;" className="flex items-center mb-2">
                        <h5 className="text-myDark font-semibold text-base font-normal leading-6">
                          doctorhub.lk@gmail.com
                        </h5>
                      </a>
                      <div className="flex flex-row gap-4 ">
                        <a href="">
                          <img src={iFb} alt="facebook" />
                        </a>
                        <a href="">
                          <img src={iWhatsapp} alt="whatsapp" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Side (Contact Form) */}
            <div className="bg-gray-50 p-5 lg:p-11 rounded-b-lg lg:rounded-r-lg ">
              <h2 className="text-myDark font-manrope text-2xl sm:text-4xl font-semibold leading-10 mb-11">
                Send Us A Message
              </h2>
              <form className="flex flex-col" onSubmit={handleSubmit}>
                <label
                  className="block _field-names text-left"
                  htmlFor="fullName"
                >
                  Full Name
                </label>
                <input
                  className=" appearance-none bg-transparent border-none w-full _text-color-dim leading-tight focus:outline-none focus:ring-0 pl-4 mt-2 mb-1"
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Name"
                />
                <hr className="_hr mb-8" />
                <label
                  className="block _field-names text-left"
                  htmlFor="fullName"
                >
                  E-mail
                </label>
                <input
                  className=" appearance-none bg-transparent border-none w-full _text-color-dim leading-tight focus:outline-none focus:ring-0 pl-4 mt-2 mb-1"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <hr className="_hr mb-8" />
                <label
                  className="block _field-names text-left"
                  htmlFor="fullName"
                >
                  Message
                </label>
                <textarea
                  className="appearance-none bg-transparent border-none w-full _text-color-dim leading-tight focus:outline-none focus:ring-0 pl-4 mt-2 mb-1"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                />
                <hr className="_hr mb-8" />

                <button
                  className=" font-semibold text-xl text-myDim bg-myGold hover:bg-opacity-80 rounded-full border border-myDim py-3 max-w-64 "
                  type="submit"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
