import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes , useLocation} from 'react-router-dom'; // Updated to use Routes instead of Switch
import './App.css';
import Home from './pages/home/Home';
import { ToastContainer } from "react-toastify";  // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';  // Import toastify CSS

import NotFound from './pages/notFound/NotFound';
import Login from './pages/login/Login';
import Wishlist from './pages/wishlist/Wishlist';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import Store from './pages/store/Store';
import Item from './pages/item/Item';
import Register from './pages/login/Register';

import ScrollToTop from './components/ScrollToTop';

import Cart from './pages/cart/Cart';

import AdminHome from './admin/pages/AdminHome';
import FooterBar from './components/FooterBar/FooterBar';
import About from './pages/About';
import PayBtn from './pages/payment/Payment';
import CashOnDelivery from './pages/payment/CashOnDelivery';
import History from './pages/history/History';



function App() {
  const location = useLocation(); // to check current route
  const isAdminRoute = location.pathname.startsWith("/admin"); // check if current path starts with '/admin'
  const shouldFooterBarShow = location.pathname === "/" || location.pathname.startsWith("/store") || location.pathname.startsWith("/item")   // check if current path starts with '/admin'

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [cartItemCount, setCartItemCount] = useState(0)
  let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  // set cart item count in the initial render
  useEffect(()=>{
    setCartItemCount(cartItems.length)
  },[])

  return (
    <div className="App">
      {!isAdminRoute && <Navbar isLoggedIn={isLoggedIn} cartItemCount = {cartItemCount} setIsLoggedIn={setIsLoggedIn} />}

      <ScrollToTop/>
      
      <Routes>
        <Route path='/' exact  element={<Home />} />
        <Route path='login' element={<Login setIsLoggedIn={setIsLoggedIn} />} />

        <Route path='register' element={<Register setIsLoggedIn={setIsLoggedIn}  />} />
        <Route path='wishlist' element={<Wishlist />} />
        <Route path='contact' element={<Contact />} />
        <Route path='item/:product_id' element={<Item  setCartItemCount = {setCartItemCount} />} />
        <Route path='store/' element={<Store />} />
        <Route path='store/:category' element={<Store />} />
        <Route path='about' element={<About/>} />
        <Route path='cart' element={<Cart setCartItemCount = {setCartItemCount} />} />
        <Route path='payment/' element={<PayBtn setCartItemCount= {setCartItemCount}/>} />
        <Route path='cashondellivery/' element={<CashOnDelivery setCartItemCount= {setCartItemCount}/>} />
        <Route path='history/' element={<History/>} />
        
        {/* Admin Routes */}
        <Route path='/admin/*' element={<AdminHome />} />
        {/* <Route path='admin/new/' element={<NewProduct/>} />
        <Route path='admin/edit/:id' element={<EditProduct/>} />
        <Route path='admin/products' element={<AllProducts/>} /> */}

        {/* Catch-all route for any undefined routes */}
        <Route path='*' element={<NotFound />} />


      </Routes>

      { !isAdminRoute && <FooterBar/>}

      {/* Only show Footer on non-admin routes */}
      {!isAdminRoute && <Footer />}
      
      {/* ToastContainer added to show toasts on any page */}
      <ToastContainer 
        position="top-right" 
        autoClose={3000}  // Toast disappears after 3 seconds
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover
        theme="colored"  // Optional: Change the theme of the toasts
      />

    </div>
  );
}

export default App;
