import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../../components/loadingPulse/Loading";
const apiBase = process.env.REACT_APP_API;

export const AllProducts = () => {
  const [category, setCategory] = useState("all");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("ASC");
  const [items, setItems] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [loading, setLoading] = useState(true);


  const fetchProducts = async () => {
    try {
      const response = await axios.post(`${apiBase}/store/`, {
        category: category,
        sort: sort,
        page: page,
        itemsPerPage: 50,
      });

      setItems(response.data.itemDetails);
      setItemCount(response.data.itemCount);
    } catch (err) {
      console.error("Failed to fetch products:", err);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [category, page, sort]);

  const handleCatChange = (e) => {
    setCategory(e.target.value);
    setLoading(true);
  };
  return (
    <div className="sm:ml-48">
       {loading && <Loading/>}
      <h2 className="text-3xl pt-8 pb-4 font-bold text-myDark text-center">All Products</h2>
      <div>
        <form>
          <label className=" tracking-wide text-gray-500 text-base font-bold ">Select Category :</label>
          <select name="category" onChange={handleCatChange}
          className="appearance-none  bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option value="all">All</option>
            <option value="tab">Tab</option>
            <option value="tabAcc">Tab Accessories</option>
            <option value="stethAcc">Steth Accessories</option>
          </select>
        </form>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6 mt-10">
        {items.map((item) => (
  <div
    key={item.product_id}
    className="flex flex-col justify-between bg-white shadow-lg rounded-lg overflow-hidden transition transform hover:scale-105 hover:shadow-2xl"
  >
    <img
      src={item.photo_url}
      alt={item.title}
      className="w-full h-48 object-cover shadow-md"
    />
    <div className="p-4 flex flex-col justify-between   ">
      <div className="">
      <h3 className="text-lg font-semibold text-myDark text-left truncate">
        {item.title}
      </h3>
      <div className="mt- flex flex-row flex-wrap gap-1 justify-start items-center">
        <span className="text-myRed font-bold text-2xl">
          Rs.{item.discount_price}.00
        </span>
        <span className="text-myDim line-through  text-base">
          Rs.{item.original_price}
        </span>
      </div>
      </div>
      
    </div>
    <div className=" w-full grid grid-cols-2 p-4 ">
        <Link to={`/admin/edit/${item.product_id}`}>
          <button className="w-full bg-myDark text-white py-1 px-4 rounded-l-full hover:bg-opacity-85 transition">
            Edit
          </button>
        </Link>
        <a target="_blank" href={`/store/${item.product_id}`}>
          <button className="w-full bg-myDim text-white py-1 px-4 rounded-r-full hover:bg-opacity-85 transition">
            View
          </button>
        </a>
      </div>
  </div>
))}

        </div>
      </div>
    </div>
  );
};
