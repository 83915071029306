import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./navBar.css";
import redHeart from "../assets/i-heart-red.svg";
import navCart from "../assets/i-cart.svg";
import navMenu from "../assets/i-menu.svg";
import Profile from "./Profile";
import axios from "axios";
import SearchResultsPopup from "./SearchResultsPopup"; // Import the popup component

const apiBase = process.env.REACT_APP_API;

const Navbar = ({ isLoggedIn, setIsLoggedIn, cartItemCount }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [isSearchExpanded, setIsSearchExpanded] = useState(false); // State to control search field width
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      setIsSearchExpanded(false); // Reset search expansion when menu closes
    }
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  const handleMenuLinkClick = () => {
    setIsMenuOpen(false);
    setIsSearchExpanded(false); // Reset search expansion when navigating
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const handleLogOut = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false)
    navigate("/login")
  }
  useEffect(() => {
    if (searchQuery) {
      setLoading(true); // Start loading
      axios
        .get(`${apiBase}/store/search?q=${searchQuery}`)
        .then((response) => {
          setResults(response.data);
        })
        .catch((error) => {
          console.error("Error fetching search results", error);
        })
        .finally(() => {
          setLoading(false); // Stop loading
        });
    } else {
      setResults([]); // Clear results if query is empty
    }
  }, [searchQuery]);

  
  const handleClosePopup = () => {
    setSearchQuery(""); // Clear search query
    setResults([]); // Clear results
    setIsSearchExpanded(false); // Collapse the search field
  };

  // Define handleSearchClose to close the search and reset states
  const handleSearchClose = () => {
    setIsSearchExpanded(false); // Collapse search field
    setSearchQuery(""); // Clear search query
    setResults([]); // Clear results
  };

  const handleSearchFocus = () => {
    setIsSearchExpanded(true); // Expand search
    setIsMenuOpen(false); // Close sidebar when search is focused
  };

  return (
    <div className="fixed w-full z-40 top-0">
      <div className="flex justify-center">
        <nav
          className={`lg:w-5/6 xl:w-4/6 w-full shadow-md _nav_bar rounded-b-[48px] lg:rounded-b-[72px] ${
            isScrolled ? "h-20 sm:h-28 _h-transition" : "h-24 sm:h-32 _h-transition"
          }`}
        >
          <div
            className={`container mx-auto h-full px-6 flex justify-between items-center w-full ${
              isScrolled ? "items-center-transition" : "items-center items-center-transition"
            }`}
          >
            <div className="w-full flex flex-col">
              <div className="flex flex-row justify-between items-center">
                {/* Logo */}
                <Link to="/" className=" text-lg sm:text-xl md:text-2xl">
                  <span className="_nav_logo_bold">DOCTOR</span>
                  <span className="_nav_logo_thin">hub.lk</span>
                </Link>
                <div className="flex flex-row items-center">
                  {/* Search Field */}
                  <div className="relative hidden md:flex items-center space-x-2 mr-6">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleInputChange}
                      onFocus={handleSearchFocus} // Expand search on focus
                      placeholder="Search"
                      className="px-5 py-2 border rounded-lg _nav_search transition-all duration-300"
                    />
                    <button type="button" className="_button _active_button space-x-2">
                      Search
                    </button>

                    {/* Search Results Popup */}
                    <div className=" hidden md:block pt-8">
                    <SearchResultsPopup
                      searchQuery={searchQuery}
                      results={results}
                      loading={loading}
                      onClose={handleClosePopup}
                    />
                    </div>
                  </div>

                  {/* Auth Buttons */}
                  <div className="hidden md:flex space-x-3 items-center">
                    {!isLoggedIn ? (
                      <Link to="/login">
                        <button className="_button _active_button">Log In</button>
                      </Link>
                    ) : (
                      <div className=""><Profile setIsLoggedIn={setIsLoggedIn} /></div>
              
            
                    )}
                    <Link to="/cart" className="size-9 flex flex-row justify-center items-center">
                      <img src={navCart} alt="cart-icon" className="_nav_cart size-6" />
                      <div className="_cart_count mb-4">{cartItemCount}</div>
                    </Link>
                    <Link to="/wishlist" className="flex flex-col items-center">
                      <img src={redHeart} className="size-6 flex items-center" alt="wishlist" />
                      <div className="_wishlist">Wishlist</div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Navigation Links */}
              <div className={`hidden md:flex space-x-4 flex flex-row justify-center nav-links-transition ${isScrolled ? "mt-4" : "mt-6"}`}>
                <Link to="/" className="_nav_links">Home</Link>
                <Link to="/store/all" className="_nav_links">Store</Link>
                <Link to="/store/tabs" className="_nav_links">Tabs</Link>
                <Link to="/store/tabaccessories" className="_nav_links">Tab Accessories</Link>
                <Link to="/store/stethaccessories" className="_nav_links">Steth Accessories</Link>
                <Link to="/about" className="_nav_links">About us</Link>
                <Link to="/contact" className="_nav_links">Contact us</Link>
              </div>
            </div>

            {/* Mobile Menu */}
            <div className="md:hidden flex items-center space-x-3">
              {!isLoggedIn ? (
                <Link to="/login">
                  <button className="_button _active_button">Log In</button>
                </Link>
              ) : (
                <Profile setIsLoggedIn={setIsLoggedIn} />
              )}
              <button onClick={toggleMenu} className="size-7">
                <img src={navMenu} alt="menu-icon" className="size-full" />
              </button>
            </div>
          </div>

          {/* Optional background overlay */}
          {isMenuOpen && (
            <div
              className="fixed inset-0 bg-black opacity-50"
              onClick={toggleMenu} // Close sidebar when clicking the overlay
            ></div>
          )}

          {/* Mobile Sidebar */}
          <div
            ref={menuRef}
            className={`fixed top-0 left-0 h-full bg-white w-64 shadow-md z-40 transition-transform ease-in-out duration-300 md:hidden ${
              isMenuOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-lg font-bold text-myDark">Menu</h2>
              <button onClick={toggleMenu} className="text-xl text-myDark">&times;</button>
            </div>
            <div className="flex flex-col p-4">
              {/* Mobile Search */}
              <div className="flex flex-col mb-4">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleInputChange}
                  onFocus={handleSearchFocus}
                  placeholder="Search"
                  className={`px-4 w-full py-2 border rounded-full transition-all duration-300 ${
                    isSearchExpanded ? "hidden" : "block w-48"
                  }`}
                />
                {isSearchExpanded && results.length > 0 && (
                  <SearchResultsPopup
                    searchQuery={searchQuery}
                    results={results}
                    loading={loading}
                    onClose={handleClosePopup}
                  />
                )}
                <div className="flex flex-col justify-center items-start space-y-6 pt-4 px-2">
                <Link to="/" className="_nav_links">Home</Link>
                <Link to="/store/all" className="_nav_links">Store</Link>
                <Link to="/store/tabs" className="_nav_links">Tabs</Link>
                <Link to="/store/tabaccessories" className="_nav_links">Tab Accessories</Link>
                <Link to="/store/stethaccessories" className="_nav_links">Steth Accessories</Link>
                <Link to="/about" className="_nav_links">About us</Link>
                <Link to="contact" className="_nav_links">Contact us</Link>
                {!isLoggedIn ? (
                <Link to="/login" className="text-myDark">
                  Log In
                </Link>
              ) : (
                <button
              onClick={()=>{handleLogOut()}}
              className="text-myDark hover:underline"
            >
              Log out
            </button>
              )}
              </div>
              </div>
            </div>
          </div>

          {/* Expanded Mobile Search Field */}
          {isSearchExpanded && (
            <div
              className="md:hidden fixed inset-0 bg-black opacity-80"
              onClick={toggleMenu} // Close sidebar when clicking the overlay
            ></div>
          )}
          {isSearchExpanded && (
            <div className="z-50 md:hidden flex flex-col w-full justify-center items-center -mt-16 md:mt-2">
            <div className="z-50 flex flex-row justify-center items-center w-full pl-4 pr-4">
              <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                placeholder="Search"
                className="w-full px-4 ring-0 border-none py-3 focus:ring-0 rounded-l-xl transition-all duration-300"
                autoFocus // Automatically focuses on this input field
              />
              <button
                onClick={handleSearchClose}
                className="bg-red-500 text-white p-3 rounded-r-xl"
              >
                Close
              </button>
            </div>
            <div className="md:hidden pt-2">
              <SearchResultsPopup
                searchQuery={searchQuery}
                results={results}
                loading={loading}
                onClose={handleClosePopup}
              />
            </div>
          </div>
            
          )}

          {/* Background overlay when search field is expanded */}
          {/* {isSearchExpanded && (
            <div
              className="md:hidden fixed inset-0 bg-black opacity-50"
              onClick={handleSearchClose} // Clicking outside closes the search
            ></div>
          )} */}
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
