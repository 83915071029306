import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import NewImg from "../components/NewImg";
const apiBase = process.env.REACT_APP_API;


const NewProduct = () => {
    const [productDetails,setProductDetails] = useState({
        title : "",
        original_price : "",
        discount_price : "",
        description : "",
        category : ""
    })
  const [itemImg, setItemImg] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate()

  
  // fetch img
  const fetchItem = async () => {
    try {
      const res = await axios.get(`${apiBase}/item/img/${id}`);
      setItemImg(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchItem(); // Fetch images on component mount
  }, [id]);

  //delete img
  const handleImgDelete = async (imageUrl) => {
    try {
        const response = await axios.delete(`${apiBase}/admin/deleteimg`, {
          data: { imageUrl },
        });
        console.log(response.data);
        alert('Image deleted successfully!');
        fetchItem();
      } catch (error) {
        console.error(error);
        alert('Error deleting image.');
      }
  }

  // handel chnages of product details
  const handelproductDetailChnage = (e) => {
    const { name, value } = e.target;

    setProductDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //handle product details upload
  const handleFileUpload = async (e) => {
    e.preventDefault();

    const { title, original_price, discount_price, description, category} = productDetails;

    if (!title || !original_price || !discount_price || !description || !category) {
      alert("set all fields")
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${apiBase}/admin/newproduct`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            title,
            original_price,
            discount_price,
            description,
            category
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert("product created successfully!");
        navigate(`/admin/edit/${data.product_id}`);
      } else if (data.error && data.error.status === 401) {
        localStorage.removeItem("token");
        alert("You need admin rights for this operation.");
        navigate("/login");
      } else {
        alert("Something went wrong.");
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
      alert("Something went wrong.");
    }
  };

  console.log(productDetails)

  return (
    <div className="pt-8">
      <h1 className="text-3xl font-bold text-myBlue text-center">New Product</h1>
     
    <br />

    <form onSubmit={handleFileUpload} class="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-4">
    <div>
        <label for="title" class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2">Title</label>
        <input type="text" name="title" id="title" 
               class="appearance-none block w-full  text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-non focus:ring-0  placeholder:opacity-50" 
               placeholder="Enter the title"
               onChange={handelproductDetailChnage} />
    </div>

    <div>
        <label for="original_price" class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2">Original Price</label>
        <input type="number" name="original_price" id="original_price" 
               class="appearance-none block w-full  text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-non focus:ring-0  placeholder:opacity-50"
               onChange={handelproductDetailChnage} />
    </div>

    <div>
        <label for="discount_price" class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2">Discount Price</label>
        <input type="number" name="discount_price" id="discount_price" 
               class="appearance-none block w-full  text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-non focus:ring-0  placeholder:opacity-50"
               onChange={handelproductDetailChnage} />
    </div>

    <div>
        <label for="description" class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2">Description</label>
        <textarea name="description" id="description" 
                  class="appearance-none block w-full h-80  text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-non focus:ring-0  placeholder:opacity-50" 
                  placeholder="Enter description" onChange={handelproductDetailChnage}></textarea>
    </div>

    <div>
        <label for="category" class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2">Category</label>
        <select name="category" id="category"  onChange={handelproductDetailChnage}
                class="appearance-none w-full  bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option value="" className="">Select</option>
            <option value="tab">Tab</option>
            <option value="tabAcc">Tab Accessories</option>
            <option value="stethAcc">Steth Accessories</option>
        </select>
    </div>

    <div>
        <button type="submit" 
                class="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Submit
        </button>
    </div>
</form>


    </div>
  );
};

export default NewProduct;
