import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'; // Import toast from react-toastify


const apiBase = process.env.REACT_APP_API;

const Register = ({setIsLoggedIn}) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
    confirm_password: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "MEDtools.lk | Register";
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, phone_number, password, confirm_password } =
      formData;

    setLoading(true);
    try {
      const response = await axios.post(`${apiBase}/auth/register`, { first_name, last_name, phone_number, password, confirm_password });
      localStorage.setItem("token", response.data.token);

      toast.success("User register successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
});
      setIsLoggedIn(true)
      navigate("/"); // Redirect to page after successful registration
    } catch (err) {
      let error_message = err.response?.data?.error?.message || "Registration failed"; // Added safe access
      console.error(error_message);
      toast.error(error_message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
       
        });

    }finally{
      setLoading(false);
    }
  };

  return (
    <section className="signupContainer bg-myLight flex flex-col items-center justify-center pt-48 sm:pt-36">
      <div className="flex flex-col justify-center items-center">
        <h1 className="p-0 m-0">
          Ayubowan to <span className="font-bold">MEDi</span>tools.lk
        </h1>
        <p className="text-center w-5/6 text-sm text-myDim font-light mb-4">
          Sri Lanka's Best and Most Trusted Source for Quality Medical Accessories
        </p>
      </div>
      <div className="flex flex-col justify-center items-center w-full max-w-md bg-myLight rounded-2xl shadow-lg p-6 space-y-4 md:space-y-6">
        <h2 className="text-xl font-bold leading-tight text-myDark">Register Now</h2>
        <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6 w-full">
          <div className="formGroup">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-myDark text-start">First Name:</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              placeholder="Enter your first name"
              value={formData.first_name} // Corrected value reference
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-1 focus:outline-none focus:ring-myBlue block w-full p-2.5"
            />
          </div>
          <div className="formGroup">
            <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-myDark text-start">Last Name:</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Enter your last name" // Corrected placeholder text
              value={formData.last_name} // Corrected value reference
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-1 focus:outline-none focus:ring-myBlue block w-full p-2.5"
            />
          </div>
          <div className="formGroup">
            <label htmlFor="phone_number" className="block mb-2 text-sm font-medium text-myDark text-start">Phone Number:</label>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              placeholder="Enter your phone number"
              value={formData.phone_number}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-1 focus:outline-none focus:ring-myBlue block w-full p-2.5"
            />
          </div>
          <div className="formGroup">
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-myDark text-start">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-1 focus:outline-none focus:ring-myBlue block w-full p-2.5"
            />
          </div>
          <div className="formGroup">
            <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium text-myDark text-start">Confirm Password:</label>
            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              placeholder="Confirm password"
              value={formData.confirm_password}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-1 focus:outline-none focus:ring-myBlue block w-full p-2.5"
            />
          </div>
          <div className="flex flex-col items-center space-y-5">
            <button type="submit" className="mt-4 text-myDark bg-myBlue hover:bg-myPurple focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-12 py-2.5 text-center flex justify-center items-center text-nowrap _button _active_button">
            {loading ? (
                "processing"
              ) : (
                "Register"
              )}
            </button>
            <div className="login flex items-center justify-between">
              <p className="text-sm font-medium text-gray-500">
                Already have an account? <Link to="/login" className="text-myBlue hover:underline">Login</Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Register;
