import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS
const apiBase = process.env.REACT_APP_API;

const NewImg = ({ product_id, refreshImages }) => {
  const [image, setImage] = useState(null);
  const [cover, setCover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleCheckboxChange = (e) => {
    setCover(e.target.checked);
  };

  const handleSubmitImg = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const formData = new FormData();
    formData.append('image', image);
    formData.append('product_id', product_id);
    formData.append('cover', cover);

    try {
      const response = await axios.post(`${apiBase}/admin/uploadimg`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      console.log(response.data);
      toast.success('Image uploaded successfully!');
      refreshImages(); // Call the function to refresh images
    } catch (error) {
      console.error(error);
      toast.error('Error uploading image.');
    }finally{
    setIsLoading(false)
    }
  };

  return (
    <div className='flex flex-col justify-center items-center shadow-lg rounded-md ' >
      <br />
      <hr />
      <br />
      <h1 className='block  tracking-wide text-gray-500 text-lg text-center font-bold mb-8'>Upload New Product Image</h1>
      <form onSubmit={handleSubmitImg} className=' flex flex-col items-center'>
        <input type="file" accept="image/*" onChange={handleImageChange} required className='border rounded-lg text-myDim ' />
        {
          !isLoading ? (
            <button type="submit" className='bg-myDim hover:bg-myDark hover:bg-opacity-65 text-white py-2 px-4 rounded-md  focus:outline-none focus:ring-none my-4'>Upload Image</button>
          )
          :
          (
        <button  className='bg-myDim hover:bg-myDark hover:bg-opacity-65 text-white py-2 px-4 rounded-md  focus:outline-none focus:ring-none my-4'>uploading..</button>
          )
        }
      </form>
    </div>
  );
};

export default NewImg;
