import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import HeroExclusive from "../../components/HeroExclusive";
import OurDiff from "../../components/OurDiff";
import GridSystem from "../../components/GridSystem";
import Contact from "../../components/Contact";
import Loading from '../../components/loadingPulse/Loading';
import Feedbacks from '../../components/Feedbacks/Feedbacks';
import Faq from '../../components/FAQ/Faq';

function Home() {
  const location = useLocation();
  const { state } = location;

      // chnage tab title
      useEffect(()=>{
        document.title = "MEDtools.lk";
      },[])

  React.useEffect(() => {
    if (state?.scrollTo) {
      document.getElementById(state.scrollTo)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state]);
  return (
    <div>
      
      <div className="flex justify-center pt-48 md:pt-72">
        <div className=" _hero_eye pb-2 m-0  flex flex-col justify-center ">
          <h1 className=" p-0 m-0 text-4xl sm:text-5xl md:text-6xl mb-2 md:mb-5">
          ආයුබෝවන් to <span className=' '><span className="_bold  ">DOCTOR</span>hub.lk</span>
          </h1>
          <h3 className=" text-lg md:text-2xl">
            Sri Lanka's Best and Most Trusted Source for Quality Medical
            Accessories
          </h3>
          <p className=" px-20">
            Empower your medical journey with our premium selection of
            accessories tailored for Sri Lankan medical students and doctors.
            From your first day in medical school to your everyday practice, we
            provide the essential tools to support your success. Shop
            confidently and enhance your practice with MedEssentials – where
            quality meets care in the heart of Sri Lanka.
          </p>
        </div>
  
      </div>
      <HeroExclusive />
<br />
<br />
      <Feedbacks/>
 
        <br />
        <br />
        <br />
        <br />
        <br />
      <OurDiff/>
      <GridSystem/>
      <Contact id = "contact" />
      <Faq/>
      
    </div>
  )
}

export default Home