import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import NewImg from "../components/NewImg";
import AddSubProduct from "../components/AddSubProduct";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS
const apiBase = process.env.REACT_APP_API;


const EditProduct = () => {
  const [productDetails, setProductDetails] = useState({
    title: "",
    original_price: "",
    discount_price: "",
    description: "",
    category: "",
  });
  const [itemImg, setItemImg] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();


  //fetch images
  const fetchItem = async () => {
    try {
      const res = await axios.get(`${apiBase}/item/img/${id}`);
      setItemImg(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchItem(); // Fetch images on component mount
  }, [id]);

  //deleting images
  const handleImgDelete = async (imageUrl) => {
    try {
      const response = await axios.delete(
        `${apiBase}/admin/deleteimg`,
        {
          data: { imageUrl },
        }
      );
      console.log(response.data);
      toast.success("Image deleted successfully!");
      fetchItem();
    } catch (error) {
      console.error(error);
      toast.success("Error deleting image.");
    }
  };
  // -------------------
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await fetch(`${apiBase}/item/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setProductDetails(data[0]);
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    };
    if (id) {
      fetchVideo(); // Fetch the video data to be edited
    }
  }, [id]);
  // -------------------

  // handel chnages
  const handelproductDetailChnage = (e) => {
    const { name, value } = e.target;

    setProductDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //handle product details
  const handleFileUpload = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const { title, original_price, discount_price, description, category } =
      productDetails;

    if (
      !title ||
      !original_price ||
      !discount_price ||
      !description ||
      !category
    ) {
      toast.success("set all fields");
      return;
    }

    try {
      const response = await fetch(
        `${apiBase}/admin/updateproduct/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title,
            original_price,
            discount_price,
            description,
            category,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success("product uploaded successfully!");
        // navigate(`/admin/edit/pdf/${data.lesson_id}`);
      } else if (data.error && data.error.status === 401) {
        localStorage.removeItem("token");
        toast.success("You need admin rights for this operation.");
        navigate("/login");
      } else {
        alert("Something went wrong.");
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
      alert("Something went wrong.");
    }
  };

  //fetch subproducts
  const fetchSubProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiBase}/item/subproducts/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw response;
      }
      const data = await response.json();
      setSubProducts(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSubProducts();
  }, []);

  //delete sub product
  const handelDeleteSubProduct = async (subid) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiBase}/admin/deletesubproduct/${subid}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        toast.success("sub product deleted");
        fetchSubProducts();
      } else {
        toast.success(response);
      }
    } catch (error) {}
  };

  // main product delete
  const handleMainProductDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiBase}/admin/deletemainproduct/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        toast.success("product deleted successfully");
        navigate("/admin/products");
      }
    } catch (error) {
      toast.success("somthing went wrong");
    }
  };

  return (
    <div className="sm:ml-48">
      <div className="text-3xl pt-8 pb-4 font-bold text-myDark text-center">
        Edit product
      </div>
      <form
        onSubmit={handleFileUpload}
        class="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-4"
      >
        <div>
          <label
            for="title"
            class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2"
          >
            Title
          </label>
          <input
            type="text"
            name="title"
            id="title"
            class="appearance-none block w-full  text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-non focus:ring-0  placeholder:opacity-50"
            placeholder="Enter the title"
            value={productDetails.title}
            onChange={handelproductDetailChnage}
          />
        </div>

        <div>
          <label
            for="original_price"
            class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2"
          >
            Original Price
          </label>
          <input
            type="number"
            name="original_price"
            id="original_price"
            class="appearance-none block w-full  text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-non focus:ring-0  placeholder:opacity-50"
            placeholder="Enter original price"
            value={productDetails.original_price}
            onChange={handelproductDetailChnage}
          />
        </div>

        <div>
          <label
            for="discount_price"
            class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2"
          >
            Discount Price
          </label>
          <input
            type="number"
            name="discount_price"
            id="discount_price"
            class="appearance-none block w-full  text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-non focus:ring-0  placeholder:opacity-50"
            placeholder="Enter discount price"
            value={productDetails.discount_price}
            onChange={handelproductDetailChnage}
          />
        </div>

        <div>
          <label
            for="description"
            class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2"
          >
            Description
          </label>
          <textarea
            name="description"
            id="description"
            className="appearance-none block w-full h-80 text-gray-700 border border-gray-300 rounded py-8 px-4 mb-3 leading-tight focus:outline-non focus:ring-0  placeholder:opacity-50"
            placeholder="Enter description"
            value={productDetails.description}
            onChange={handelproductDetailChnage}
          ></textarea>
        </div>

        <div>
          <label
            for="category"
            class="block  tracking-wide text-gray-500 text-bas text-left font-bold mb-2"
          >
            Category
          </label>
          <select
            name="category"
            id="category"
            onChange={handelproductDetailChnage}
            class="appearance-none w-full  bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={productDetails.category}
          >
            <option value="">Select</option>
            <option value="tab">Tab</option>
            <option value="tabAcc">Tab Accessories</option>
            <option value="stethAcc">Steth Accessories</option>
          </select>
        </div>
        <div>
          <button
            type="submit"
            class="w-full bg-myDim hover:bg-myDark hover:bg-opacity-65 text-white py-2 px-4 rounded-md  focus:outline-none focus:ring-none"
          >
            Update
          </button>
        </div>
      </form>
      {/* subproducts */}
      <br />
      {itemImg.map((i) => (
        <div
          key={i.photo_url}
          className="flex flex-col items-center justify-center p-4 rounded-lg space-y-4"
        >
          <img
            className="rounded-lg shadow-lg w-52 h-auto object-cover"
            src={i.photo_url}
            alt="Product"
          />
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
            onClick={() => handleImgDelete(i.photo_url)}
          >
            Delete
          </button>
        </div>
      ))}
      <NewImg product_id={id} refreshImages={fetchItem} /> <br />
      {/* <h4>Sub products</h4> */}
      <div className="mt-28 grid grid-cols-1 lg:grid-cols-3">
        <div className=" col-span-1">
          <AddSubProduct id={id} refreshSub={fetchSubProducts} />
        </div>
        <div className="overflow-x-auto border col-span-2 bg-white text-myDark">
          <table className="w-full border-collapse border border-gray-200 bg-white">
            <thead className="bg-white">
              {/* <tr className=""><th className="border  border-gray-200 px-4 text-2xl font-semibold py-2 text-center ">Sub products</th></tr> */}
              <tr>
                <th className="border border-gray-200 px-4 py-2 text-center">
                  Category
                </th>
                <th className="border border-gray-200 px-4 py-2 text-left">
                  Color
                </th>
                <th className="border border-gray-200 px-4 py-2 text-left">
                  Price
                </th>
                <th className="border border-gray-200 px-4 py-2 text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {subProducts.map((i) => (
                <tr key={i.cat_1}>
                  <td className="border border-gray-200 px-4 py-2">
                    {i.cat_1}
                  </td>
                  <td className="border border-gray-200 px-4 py-2">
                    {i.color}
                  </td>
                  <td className="border border-gray-200 px-4 py-2">
                    {i.price}
                  </td>
                  <td
                    className="border border-gray-200 px-4 py-2 cursor-pointer text-red-600"
                    onClick={() => {
                      handelDeleteSubProduct(i.sub_product_id);
                    }}
                  >
                    <div className="flex flex-row items-center justify-center space-x-1">
                      <RiDeleteBin6Line className="text-myRed " />
                      Delete
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* delete product */}
      <div className="my-24 bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto text-center">
        <h4 className="text-xl font-semibold text-gray-800 mb-4">
          Delete Product
        </h4>
        <button
          onClick={() => {
            handleMainProductDelete();
          }}
          className="bg-red-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default EditProduct;
