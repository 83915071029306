import React from "react";
import "./feedbacks.css";
const testimonials = [
  {
    name: "Alex Badejo",
    title: "Head of Product at Google",
    text: `"As a busy professional, inbox has been a game-changer for me. The smart suggested responses have saved me countless hours of typing and the AI-powered reminders ensure that I never miss an important email. Thank you, inbox!"`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Alex Badejo",
    screenshot: "https://placehold.co/300x200",
  },
  {
    name: "Rachel Wright",
    title: "VP design at Hu.ma.ne",
    text: `"What I love about inbox is that they're very responsive and really listen to their customers. It's one of those products where I'm happy to pay premium for a great experience and support."`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Rachel Wright",
    screenshot: "https://placehold.co/300x200",
  },
  {
    name: "Zahir Mays",
    title: "Developer relations, Github",
    text: `"Inbox has completely revolutionized my email experience! The AI features have significantly reduced the time I spend sorting and organizing my inbox, allowing me to focus on more important tasks. Highly recommended!"`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Zahir Mays",
    screenshot: "https://placehold.co/300x200",
  },
  {
    name: "Peter Foh",
    title: "Freelance software engineer",
    text: `"Inbox's email tracking feature has been a game-changer for my sales team. We now have real-time insights into who has opened our emails and when, allowing us to follow up at the perfect time."`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Peter Foh",
    screenshot: "https://placehold.co/300x200",
  },
  {
    name: "Lucy Bond",
    title: "Product designer, Stripe",
    text: `"I love how inbox automatically organizes my emails into different categories, making it easy to find and prioritize messages."`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Lucy Bond",
    screenshot: "https://placehold.co/300x200",
  },
  {
    name: "Rene Wells",
    title: "Head of Product at Google",
    text: `"I used to spend hours searching for old emails, but inbox's powerful search capabilities have made finding past conversations a breeze. The AI algorithms are incredibly accurate, and I can now locate any email within seconds."`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Rene Wells",
    screenshot: "https://placehold.co/300x200",
  },
  {
    name: "Alec Whitten",
    title: "Product Manager, Cashapp",
    text: `"I was skeptical at first, but inbox has surpassed my expectations. The AI-powered email management features are top-notch, and the customer support is always responsive and helpful."`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Alec Whitten",
    screenshot: "https://placehold.co/300x200",
  },
  {
    name: "Candice Wu",
    title: "Freelance copywriter",
    text: `"Inbox has simplified my email management like no other platform. The AI-enhanced email threading brings related messages together, making it easy to follow conversations."`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Candice Wu",
    screenshot: "https://placehold.co/300x200",
  },
  {
    name: "Eva Bond",
    title: "Customer success, Shopify",
    text: `"Inbox's AI-powered email sorting has saved me countless hours. I can now focus on responding to important emails without getting bogged down by spam and less important messages."`,
    image: "https://placehold.co/50x50",
    alt: "Profile picture of Eva Bond",
    screenshot: "https://placehold.co/300x200",
  },
];

function Feedbacks() {
  const [showMore, setShowMore] = React.useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className=" max-w-screen-lg text-myDark p-">
        <section className=" px-4   fade-in">
        <h1 className="  flex justify-center  text-2xl md:text-4xl">Valuable Feedback from Our Clients</h1>
          {/* <h2 className="text-3xl font-bold text-center mb-4">
            What our users say
          </h2> */}
          <p className="text-center mb-8 opacity-25">
            These are what people who've used inbox has to say about the product
          </p>
          <br />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {testimonials.slice(0, 3).map((testimonial, index) => (
              <div
                key={index}
                className="bg-white p-4 rounded-lg shadow-md testimonial-card relative"
              >
                <div className="flex items-center mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.alt}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <h3 className="font-bold text-left">{testimonial.name}</h3>
                    <p className="text-xs text-left opacity-50">{testimonial.title}</p>
                  </div>
                </div>
                <p className="text-sm text-justify">{testimonial.text}</p>
                <img
                  src={testimonial.screenshot}
                  alt={`Screenshot of review by ${testimonial.name}`}
                  className="absolute top-0 left-0 w-full h-full object-cover opacity-0 hover:opacity-100 transition-opacity duration-300"
                />
              </div>
            ))}
          </div>
          <div
            className={`show-more-container ${
              showMore ? "expanded" : "collapsed"
            }`}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              {testimonials.slice(3).map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-white p-4 rounded-lg shadow-md testimonial-card relative"
                >
                  <div className="flex items-center mb-4">
                    <img
                      src={testimonial.image}
                      alt={testimonial.alt}
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <div>
                      <h3 className="font-bold text-left">{testimonial.name}</h3>
                      <p className="text-xs text-left opacity-50">{testimonial.title}</p>
                    </div>
                  </div>
                  <p className="text-sm">{testimonial.text}</p>
                  <img
                    src={testimonial.screenshot}
                    alt={`Screenshot of review by ${testimonial.name}`}
                    className="absolute top-0 left-0 w-full h-full object-cover opacity-0 hover:opacity-100 transition-opacity duration-300"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-4">
            <button className="text-myDim hover:underline" onClick={toggleShowMore}>
              {showMore ? "Show less" : "Show more"}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Feedbacks;
