import { Tabs } from "flowbite-react";
import {
  RiDiscussFill,
  RiMailStarFill,
  RiFileChartFill,
  RiAccountCircleFill,
} from "react-icons/ri";
import Img from "../assets/products/displayImage1.jpg";
import StarRating from "./StarRating";
import ReviewItem from "./ReviewCard";

export function ProductFooter({ description, reviewsObj }) {
  return (
    <Tabs aria-label="Default tabs" variant="default" className="w-full mt-10">
      <Tabs.Item
        active
        title="Description"
        icon={RiFileChartFill}
        className="text-myDim"
      >
        <div className="flex flex-col">
          {/* description */}
          <div
            className="text-justify text-myDark"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </Tabs.Item>
      <Tabs.Item
        title="Reviews (66)"
        icon={RiMailStarFill}
        className=" focus:ring-1"
      >
        <div className="bg-white bg-opacity-75 w-full rounded-sm text-myDark py-4 flex flex-col items-start">
          <div className="flex flex-col items-start pl-4">
            <h1 className="font-medium text-6xl">{reviewsObj.star_count}</h1>
            <StarRating rating={reviewsObj.star_count} />
            <p className="text-base font-normal">All from verified purchases</p>
          </div>
        </div>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">

        {reviewsObj.reviews && reviewsObj.reviews.length > 0 ? (
                        reviewsObj.reviews.map((review) => (
                            <ReviewItem key={review.review_id} review={review} />
                        ))
                    ) : (
                        <p className="text-myDim text-left pl-4">No reviews available.</p>
        )}
        </ul>
      </Tabs.Item>

      <Tabs.Item disabled title="Ask Question" icon={RiDiscussFill}>
        Disabled content
      </Tabs.Item>
    </Tabs>
  );
}

