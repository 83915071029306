import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";

const ImageSlider = ({ p_array }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [magnifyStyle, setMagnifyStyle] = useState({});
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Auto slide every 60 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % p_array.length);
    }, 60000);
    return () => clearInterval(interval);
  }, [p_array.length]);

  // Handle swipe
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => (prevIndex + 1) % p_array.length),
    onSwipedRight: () =>
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + p_array.length) % p_array.length
      ),
    preventScrollOnSwipe: true,
    trackMouse: true, // This allows mouse swipe on desktop
  });

  // Handle magnify effect
  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setMagnifyStyle({
      backgroundPosition: `${x}% ${y}%`,
    });
  };

  const handleMouseLeave = () => {
    setMagnifyStyle({});
  };

  // Toggle fullscreen mode
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + p_array.length) % p_array.length
      );
    } else if (e.key === "ArrowRight") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % p_array.length);
    }
  };

  // Set up and clean up keydown event listener
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="w-full md:max-w-64 mx-auto mb-8 md:mb-0 relative">
      {/* Main Slider */}
      <div {...swipeHandlers} className="relative">
        <div
          className={`relative w-full h-96 md:size-64 object-cover rounded-md transition duration-700 ease-in-out cursor-zoom-in ${
            isFullscreen ? "cursor-default" : ""
          }`}
          style={{
            backgroundImage: `url(${p_array[currentIndex]?.photo_url})`,
            backgroundSize: isFullscreen
              ? "contain"
              : magnifyStyle.backgroundPosition
              ? "200%"
              : "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: isFullscreen
              ? "center"
              : magnifyStyle.backgroundPosition || "center",
          }}
          onMouseMove={isFullscreen ? null : handleMouseMove}
          onMouseLeave={isFullscreen ? null : handleMouseLeave}
          onClick={toggleFullscreen} // Open fullscreen on click
        >
          {/* Fullscreen pop-up */}
          {isFullscreen && (
            <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
              <img
                src={p_array[currentIndex]?.photo_url}
                alt={`Slide ${currentIndex}`}
                className="max-w-full max-h-full object-contain"
              />
              {/* Left arrow (Fullscreen) */}
              <button
                className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 text-white rounded-full"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent fullscreen toggle
                  setCurrentIndex(
                    (prevIndex) =>
                      (prevIndex - 1 + p_array.length) % p_array.length
                  );
                }}
              >
                &#8592;
              </button>

              {/* Right arrow (Fullscreen) */}
              <button
                className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 text-white rounded-full"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent fullscreen toggle
                  setCurrentIndex(
                    (prevIndex) => (prevIndex + 1) % p_array.length
                  );
                }}
              >
                &#8594;
              </button>

              <button
                // onClick={toggleFullscreen}
                className="absolute top-4 right-4 text-white bg-black bg-opacity-70 p-2 rounded-full"
              >
                ✕
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Thumbnails */}
      <div className="flex justify-start flex-wrap mt-4">
        {p_array.map((image, index) => (
          <button
            key={image.photo_id} // Ensure unique key using image.photo_id
            onClick={() => setCurrentIndex(index)}
            className={`border-2 ${
              currentIndex === index ? "border-blue-500" : "border-transparent"
            } rounded-lg transition duration-200 m-1`}
          >
            <img
              src={image.photo_url} // Use the correct property for the image
              alt={`Thumbnail ${image.photo_id}`}
              className="w-16 h-16 object-cover rounded-lg"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
