import React from 'react';
import './loading.css';

function Loading() {
    return (
        <div className="loading-bar-container fixed top-0 left-0 w-full">
            <div className="loading-bar"></div>
        </div>
    );
}

export default Loading;
