// Profile.js
import React, { useState, useRef, useEffect } from 'react';
import imageClient from '../data/imageClient2.jpeg';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';

const apiBase = process.env.REACT_APP_API;

const Profile = ({setIsLoggedIn}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [profileInfo, setProfileInfo] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const res = await axios.get(`${apiBase}/profile/`,{
          headers : {
            Authorization : `${token}`
          }
        });
        setProfileInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, []);


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  //handle log out
  const handleLogOut = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false)
    navigate("/login");
  }

  return (
    <div className="relative">
      <img
        onClick={() => setIsOpen(!isOpen)}
        className="w-10 md:w-8 h-8 object-cover border rounded-full cursor-pointer"
        src={imageClient}
        alt="User dropdown"
      />
      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
        >
          <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
            <div>{profileInfo.profile ? profileInfo.profile.first_name  : "loading..."} </div>
            <div className="font-medium truncate">{profileInfo.profile ? profileInfo.profile.phone_number : "Loading..."}</div>
          </div>
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
              { profileInfo.profile && profileInfo.profile.type ==="admin" && 
              <li>
              <Link
                to="/admin"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                DashBoard
              </Link>
            </li>}
            <li>
              <Link
                to="/cart"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
               My cart
              </Link>
            </li>
            <li>
              <Link
                to="/wishlist"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                My wishlist
              </Link>
            </li>
            <li>
              <Link
                to="/history"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                My Orders
              </Link>
            </li>
          </ul>
          <div className="py-1">
            <button
              onClick={()=>{handleLogOut()}}
              className="m-auto block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor:pointer"
            >
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
