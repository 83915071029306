import React from 'react';
import { RiHome6Line, RiShoppingCartLine, RiAccountCircleLine } from "react-icons/ri";
import { AiOutlineShop } from "react-icons/ai";
import { LiaUsersSolid } from "react-icons/lia";
import { Link, useLocation } from 'react-router-dom';
import { FaRegHeart } from "react-icons/fa6";

const FooterBar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to check if current path matches any from a list of paths
  const isActive = (paths, exact = false) => {
    return exact ? paths.includes(currentPath) : paths.some(path => currentPath.startsWith(path));
  };

  return (
    <div className="fixed bottom-0 w-full bg-white shadow-lg block md:hidden z-30 rounded-t-3xl border border-myDim border-opacity-40">
      <div className="flex justify-around items-center py-2 px-6">
        
        {/* Home - check for exact match */}
        <div className="flex flex-col items-center group">
          <Link to={"/"} className="flex flex-col items-center">
            <RiHome6Line
              className={`text-2xl ${isActive(['/'], true) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`} 
            />
            <span className={`text-sm ${isActive(['/'], true) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`}>
              Home
            </span>
            {isActive(['/'], true) && <span className="block w-5 h-1 bg-myDim rounded-full mt-1"></span>}
          </Link>
        </div>

        {/* Shop - active if the path starts with /store/ or /item/ */}
        <div className="flex flex-col items-center group">
          <Link to={"/store/all"} className="flex flex-col items-center">
            <AiOutlineShop
              className={`text-2xl ${isActive(['/store/', '/item/']) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`} 
            />
            <span className={`text-sm ${isActive(['/store/', '/item/']) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`}>
              Shop
            </span>
            {isActive(['/store/', '/item/']) && <span className="block w-5 h-1 bg-myDim rounded-full mt-1"></span>}
          </Link>
        </div>

        {/* Wishlist */}
        <div className="flex flex-col items-center group">
          <Link to={"/wishlist"} className="flex flex-col items-center">
            <FaRegHeart
              className={`text-2xl ${isActive(['/account']) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`} 
            />
            <span className={`text-sm ${isActive(['/account']) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`}>
              Wishlist
            </span>
            {isActive(['/wishlist']) && <span className="block w-5 h-1 bg-myDim rounded-full mt-1"></span>}
          </Link>
        </div>

        {/* Cart */}
        <div className="flex flex-col items-center group">
          <Link to={"/cart"} className="flex flex-col items-center">
            <RiShoppingCartLine
              className={`text-2xl ${isActive(['/cart']) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`} 
            />
            <span className={`text-sm ${isActive(['/cart']) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`}>
              Cart
            </span>
            {isActive(['/cart']) && <span className="block w-5 h-1 bg-myDim rounded-full mt-1"></span>}
          </Link>
        </div>

        {/* Batch */}
        <div className="flex flex-col items-center group">
          <Link to={"/#batch"} className="flex flex-col items-center">
            <LiaUsersSolid
              className={`text-2xl ${isActive(['/batch']) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`} 
            />
            <span className={`text-sm ${isActive(['/batch']) ? 'text-myDim' : 'text-myDark'} transition-colors duration-300`}>
              Batch
            </span>
            {isActive(['/batch']) && <span className="block w-5 h-1 bg-myDim rounded-full mt-1"></span>}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterBar;
