import React from "react";
import { RiAccountCircleFill } from "react-icons/ri";
import StarRating from "./StarRating"; // Assuming your StarRating component is in the same directory

const ReviewItem = ({ review }) => {
  return (
    <li className="bg-white bg-opacity-75">
      <div className="space-y-4 p-8">
        <div className="flex flex-row items-center">
          <div>
            <RiAccountCircleFill className="text-myDim size-16" />
          </div>
          <div className="flex flex-col items-start">
            <p>
              <StarRating rating={review.star_count} />
            </p>
            <p className="text-sm font-normal text-myDim">
              {/* print name while capitalizing the first lettter of name */}
              {`${review.first_name} ${review.last_name}` }
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-4">
          <p className="text-sm font-normal text-myDark text-justify">
            {review.comment}
          </p>
          <h2 className="text-sm text-myDim font-medium">
            {review.date.split("T")[0]}
          </h2>
        </div>
      </div>
    </li>
  );
};

export default ReviewItem;
