import React, { useEffect, useState } from "react";
import Loading from "../../components/loadingPulse/Loading";
const apiBase = process.env.REACT_APP_API;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the API
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${apiBase}/admin/seeorders/`, {
          method: "GET",
        });
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  function extractTimeFromISOString(dateString) {
    const date = new Date(dateString);
    const hours = date.getUTCHours(); // Get hours (UTC)
    const minutes = date.getUTCMinutes(); // Get minutes (UTC)
    const seconds = date.getUTCSeconds(); // Get seconds (UTC)

    // Format the time
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return formattedTime;
  }

  return (
    <div className="mt-12">
      {loading && <Loading />}
      {orders.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mx-auto max-w-screen-lg md:ml-48">
          {orders.map((order) => (
            <div
              key={order.request_id}
              className="border border-gray-300 p-6 rounded-lg shadow-md bg-white"
            >
              <h2 className="text-xl font-bold mb-4">
                Order ID: {order.order_id}
              </h2>
              <table className="table-auto w-full text-left border-collapse">
                <tbody>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-gray-700 font-semibold">
                      Name:
                    </th>
                    <td className="px-4 py-2">
                      {order.first_name} {order.last_name}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-gray-700 font-semibold">
                      Phone:
                    </th>
                    <td className="px-4 py-2">{order.phone}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-gray-700 font-semibold">
                      Email:
                    </th>
                    {/* Add text wrapping and ellipsis for long email */}
                    <td className="px-4 py-2 break-all overflow-hidden text-ellipsis">
                      {order.email}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-gray-700 font-semibold">
                      Address:
                    </th>
                    <td className="px-4 py-2">{order.address}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-gray-700 font-semibold">
                      city:
                    </th>
                    <td className="px-4 py-2">{order.city}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-gray-700 font-semibold">
                      Payment:
                    </th>
                    <td className="px-4 py-2">
                      {order.status === 1 ? "Online" : "Cash On Delivery"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-gray-700 font-semibold">
                      Date:
                    </th>
                    <td className="px-4 py-2">
                      {new Date(order.date).toLocaleDateString()}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-gray-700 font-semibold">
                      Time:
                    </th>
                    <td className="px-4 py-2">
                      {extractTimeFromISOString(order.date)} (24h)
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      className="font-semibold text-lg mt-4 text-gray-900"
                    >
                      Order Items:
                    </td>
                  </tr>
                </tbody>
              </table>

              <ul className="mt-4">
                {JSON.parse(order.cart).map((item, idx) => (
                  <li
                    key={idx}
                    className="border-b last:border-0 mb-2 p-3 bg-gray-50 rounded-lg"
                  >
                    <p>
                      <strong>Product:</strong> {item.title}
                    </p>
                    <p>
                      <strong>Category:</strong> {item.cat_1}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {item.quantity}
                    </p>
                    {item.color && (
                      <p>
                        <strong>Color:</strong> {item.color}
                      </p>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};

export default Orders;
