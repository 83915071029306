import React from "react";
import "./ourDiff.css";
import iQuality from '../assets/i-quality.svg'
import iStore from '../assets/i-store.svg'
import iThrusted from '../assets/i-thrusted.svg'
import iJourney from '../assets/i-journey.svg'
import vShort from '../assets/v-short.mp4'
import ReactPlayer from 'react-player';


import sampleVideoImage from '../assets/sample-video-image.png'

function OurDiff() {
  return (
    <div>
        <h1 className="flex justify-center mb-20 text-2xl md:text-4xl">Discover the DOCTORhub.lk Difference</h1>
      <div className="flex justify-center">
    <div className="flex flex-col-reverse md:flex-row-reverse justify-between  _our-diff">
      <div className=" _video">
        {/* <img src={sampleVideoImage} alt="" /> */}
        <div className="video-container">
          <iframe
            width="360"
            height="640"
            src="https://www.youtube.com/embed/Uu-9m48uk1Q?si=Zl4vUoHzCNv6nV3i"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className=" _side-list flex flex-col justify-center">
        <h5 className="">
          Why DOCTORhub.lk is Sri Lanka's Best and Most Trusted Source for Medical
          Accessories
        </h5>
        <ul>
          <li className="_list-item flex flex-row  justify-center items-center ">
            <div className="_icon-back flex justify-center  items-center">
            <img src={iQuality} alt="" className="h-8 w-8" />
            </div>
            <div className="_discription flex flex-col text-left ml-4">
                <h6>High-Quality Medical Tablets: </h6>
            <p>
              Tailored for the needs of medical students and doctors, offering
              the perfect blend of technology and convenience.
            </p></div>
          </li>
          <li className="_list-item flex flex-row justify-center items-center ">
            <div className="_icon-back flex justify-center  items-center">
            <img src={iStore} alt="" className="w-5 h-5"/>
            </div>
            <div className="_discription flex flex-col text-left ml-4">
                <h6>Comprehensive Range of Accessories:</h6>
            <p>
            From stethoscopes to sphygmomanometers, our products meet the highest standards of quality and reliability.
            </p></div>
          </li>
          <li className="_list-item flex flex-row  justify-center items-center ">
            <div className="_icon-back flex justify-center  items-center">
            <img src={iThrusted} alt="" className="w-6 h-6"/>
            </div>
            <div className="_discription flex flex-col text-left ml-4">
                <h6>Trusted by Thousands:</h6>
            <p>
            Join the MedEssentials family and see why healthcare professionals across Sri Lanka rely on us.
            </p></div>
          </li>
          <li className="_list-item flex flex-row  justify-center items-center ">
            <div className="_icon-back flex justify-center  items-center">
            <img src={iJourney} alt="" className="h-6 w-6" />
            </div>
            <div className="_discription flex flex-col text-left ml-4">
                <h6>Supporting Your Journey:</h6>
            <p>
            From the classroom to the clinic, we are here to empower your medical journey with the best in quality and reliability.
            </p></div>
          </li>
        </ul>
      </div>
    </div>
    </div>
    </div>
  );
}

export default OurDiff;
