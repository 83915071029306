import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "./footer.css";
import iFb from "../assets/i-fb.svg";
import iWhatsapp from "../assets/i-whatsapp.svg";
function Footer() {
  const navigate = useNavigate();

  const goToContact = () => {
    navigate('/', { state: { scrollTo: 'contact' } });
  };
  return (
    <div className="flex flex-col justify-center items-center bottom-0 mb-20 md:mb-0">
      <div className="flex flex-col justify-center items-center mt-20 shadow-lg _footer">
        <div className="block md:hidden">
          <div className="flex flex-row items-center justify-center space-x-4 sm:space-x-32 lg:space-x-32 py-10">
            <div className="flex flex-col items-start justify-center">
              <Link to="/" className="">
                <span className="_footer_logo_bold">MED</span>
                <span className="_footer_logo_thin">tools.lk</span>
              </Link>
  
              <div className="flex flex-col items-start _contact">
                <h3>Contact</h3>
                <Link to="#">meditools.lk@gmail.com</Link>
                <div className="flex flex-row gap-3 py-2">
                  <Link to="#">
                    <img src={iFb} alt="facebook" />
                  </Link>
                  <Link to="#">
                    <img src={iWhatsapp} alt="whatsapp" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col _footer-links">
              <div className="flex flex-col items-start">
                <h5>Quick Links</h5>
                <Link to="/">Home</Link>
                <Link to="/about">About us</Link>
                <Link to="/contact" onClick={goToContact}>Contact us</Link>
              </div>
              <div className="flex flex-col items-start">
                <h5>Quick Access to Store</h5>
                <Link to="/store/tabs">Tabs & Accessories</Link>
                <Link to="/store/steth">Steth Accessories</Link>
                <Link to="/combo">Combo pack</Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center pb-3 _copyrights">
            <p>Copyright @ 2024 MEDtools - All Right Reserved</p>
            <p>
              Design, Development & Powered by{' '}
              <span>
                <Link to="https://www.facebook.com/profile.php?id=61563620580575" target="_blank">devX Team</Link>
              </span>.
            </p>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="flex flex-row items-center justify-center space-x-12 lg:space-x-32 py-10">
            <Link to="/" className="ml-3">
              <span className="_footer_logo_bold">MED</span>
              <span className="_footer_logo_thin">tools.lk</span>
            </Link>
            <div className="flex flex-col _footer-links">
              <div className="flex flex-col items-start">
                <h5>Quick Links</h5>
                <Link to="/">Home</Link>
                <Link to="/about">About us</Link>
                <Link to="/contact" onClick={goToContact}>Contact us</Link>
              </div>
              <div className="flex flex-col items-start">
                <h5>Quick Access to Store</h5>
                <Link to="/store/tabs">Tabs</Link>
                <Link to="/store/tabaccessories">Tab Accessories</Link>
                <Link to="/store/stethaccessories">Steth Accessories</Link>
              </div>
            </div>
            <div>
              <div className="flex flex-col md:mr-5 ml-5 items-start _contact">
                <h3>Contact</h3>
                <a href="mailto:medtools.lk@gmail.com">medtools.lk@gmail.com</a>
                <div className="flex flex-row gap-3 py-2">
                  <Link to="#">
                    <img src={iFb} alt="facebook" />
                  </Link>
                  <Link to="#">
                    <img src={iWhatsapp} alt="whatsapp" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center pb-3 _copyrights">
            <p>Copyright @ 2024 MEDtools - All Right Reserved</p>
            <p>
              Design, Development & Powered by{' '}
              <span>
                <Link to="https://www.facebook.com/profile.php?id=61563620580575" target="_blank">devX Team</Link>
              </span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
