import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"; // Import React Router hooks

import "./gridSystem.css";

import img0 from "../assets/Grid-image-0.png";
import img1 from "../assets/Grid-image-0.png";
import img2 from "../assets/Grid-image-0.png";
import iTabs from "../assets/i-tabs.svg";
import iOther from "../assets/i-other.svg";
import iEssential from "../assets/i-essential.svg";
import iOffers from "../assets/i-offers.svg";

const GridSystem = () => {
  const images = [img0, img1, img2];
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselInterval = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    startCarousel();
    return () => {
      stopCarousel();
    };
  }, []);

  const startCarousel = () => {
    carouselInterval.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
  };

  const stopCarousel = () => {
    clearInterval(carouselInterval.current);
  };

  const goToSlide = (index) => {
    stopCarousel();
    setCurrentIndex(index);
    startCarousel();
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      // Swipe left
      goToSlide((currentIndex + 1) % images.length);
    }

    if (touchStartX.current - touchEndX.current < -50) {
      // Swipe right
      goToSlide((currentIndex - 1 + images.length) % images.length);
    }
  };


  useEffect(() => {
    // Check if there is a hash in the URL
    const handleHash = () => {
      const sectionId = window.location.hash.substring(1); // Remove the '#' from the ID
      if (sectionId) {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' }); // Scroll to the section
        }
      }
    };

    // Call the function to handle hash on component mount
    handleHash();

    // Add an event listener to handle hash changes
    window.addEventListener('hashchange', handleHash);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHash);
    };
  }, []);

  return (
    <div>
      <h1 className="flex justify-center mb-4 md:mb-16 mt-20  text-2xl md:text-4xl">
      Explore Our Offerings
      </h1>
      <div className=" flex  justify-center">
        <div class="_grid flex flex-col  items-center ">
          <div className="flex flex-col md:flex-row _first_2  ">
            <div class=" mt-5 w-2/3 flex flex-col _section-1   md:justify-center md:items-start items-center">
              <div className="md:ml-24 px-8 md:px-0 md:w-3/6 flex flex-col  md:items-start items-center ">
                <img src={iTabs} alt="" className="size-8" />
                <h3 className="md:text-left text-center">
                  Explore Our Tablets
                </h3>
                <p className="md:text-left text-center mb-1">
                Discover special tablet offers for medical students: affordable, high-performance devices tailored to meet your academic and professional needs.
                </p>
                
                <Link to="/store/tabs"><button className="_button _yellow_button ">Explore</button></Link>
              </div>
            </div>
            <div class="md:ml-5 w-1/3 mt-5 _section-2 mb-0 bg-slate-500 flex  items-center md:justify-start  justify-center">
              <div className="md:ml-5 md:mr-3  flex flex-col  md:items-start items-center px-8 md:px-0">
                <img src={iOther} alt="" className="size-8" />
                <h3 className="md:text-left text-center">Tab Accessories</h3>
                <p className="md:text-left text-center mb-1">
                Enhance your tablet with premium accessories for style, convenience, protection.
                </p>
                <Link to="/store/tabaccessories"><button className="_button _yellow_button ">Explore</button></Link>
              </div>
            </div>
          </div>

          <div class=" w-full  mt-5 mb-5 _section-3 mb-0 bg-slate-500 flex  items-center md:justify-start  justify-center">
            <div className="md:ml-24 px-8 md:px-0 md:w-3/6 flex flex-col  md:items-start items-center">
              <img src={iEssential} alt="" className="size-8" />
              <h3 className="md:text-left text-center">Steth Accessories</h3>
              <p className="md:text-left text-center mb-1">
                Enhance your experience with our premium steth accessories.
                Perfect for both students and professionals.
              </p>
              <Link to="/store/stethaccessories"><button className="_button _yellow_button ">Explore</button></Link>
            </div>
          </div>

          <div id="batch" class=" w-full   _section-4 mb-0 flex  items-center">
            <div className="mt- flex md:flex-row flex-col md:justify-between items-center">
              <div className="lg:ml-24  md:ml-10 md:mt-0 mt-0 md:mb-0 mb-12 w-2/6 flex flex-col justify-center md:items-start items-center _dis-4 px-8 md:px-0 ">
                <img src={iOffers} alt="" className="size-8 " />
                <h3 className="md:text-left text-center">
                  Special Offers for Batch Wise Purcheses
                </h3>
                <p className="md:text-left text-center mb-1">
                Take advantage of exclusive batch offers on tablets and medical supplies, tailored specifically for medical students. Maximize savings on essential products for your studies.
                </p>
                <a target="_blank" href="https://drive.google.com/file/d/1B1FkD0_hYlzz3GMPZDYxssAS30eyKsJM/view?usp=sharing"><button className="_button _yellow_button ">More about</button></a>
              </div>
              <div>
                {/* <img src={img4} alt="" /> */}
                <div className="relative _carousel  ">
                  <div
                    className="overflow-hidden relative rounded-lg shadow-lg "
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                  >
                    <div
                      className="flex transition-transform ease-in-out duration-500"
                      style={{
                        transform: `translateX(-${currentIndex * 100}%)`,
                      }}
                    >
                      {images.map((img, index) => (
                        <div key={index} className="w-full flex-shrink-0">
                          <img
                            src={img}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-auto"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                      {images.map((_, index) => (
                        <button
                          key={index}
                          className={`h-2 w-2 rounded-full ${
                            currentIndex === index
                              ? "bg-gray-800"
                              : "bg-gray-400"
                          }`}
                          onClick={() => goToSlide(index)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridSystem;
