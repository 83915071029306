import React from "react";
import { Link } from "react-router-dom";
import "./heroExclusive.css";
import { Carousel } from "flowbite-react";
import slideImg1 from "../assets/slide0.png";
import slideImg2 from "../assets/slide1.png";
import slideImg3 from "../assets/slide2.png";
import linkIcon from "../assets/i-link-arrow.svg";

function HeroExclusive() {
  const divStyle1 = {
    backgroundImage: `url(${slideImg1})`,
    height: "500px", // Set your desired height
    backgroundSize: "cover", // This will cover the entire div
    backgroundPosition: "center", // Center the image
    backgroundRepeat: "no-repeat", // Prevent the image from repeating
  };

  const divStyle2 = {
    backgroundImage: `url(${slideImg2})`,
    height: "500px", // Set your desired height
    backgroundSize: "cover", // This will cover the entire div
    backgroundPosition: "center", // Center the image
    backgroundRepeat: "no-repeat", // Prevent the image from repeating
  };

  const divStyle3 = {
    backgroundImage: `url(${slideImg3})`,
    height: "500px", // Set your desired height
    backgroundSize: "cover", // This will cover the entire div
    backgroundPosition: "center", // Center the image
    backgroundRepeat: "no-repeat", // Prevent the image from repeating
  };

  const customTheme = {
    root: {
      base: "relative h-full w-full",
      leftControl: "absolute left-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none carousel-control",
      rightControl: "absolute right-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none carousel-control"
    },
    indicators: {
      active: {
        off: "carousel-indicator",
        on: "carousel-indicator-active"
      },
      base: "h-3 w-3 rounded-full",
      wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
    },
    item: {
      base: "absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
      wrapper: {
        off: "w-full flex-shrink-0 transform cursor-default snap-center",
        on: "w-full flex-shrink-0 transform cursor-grab snap-center"
      }
    },
    control: {
      base: "inline-flex h-8 w-8 items-center justify-center rounded-full carousel-control",
      icon: "h-5 w-5 carousel-control-icon sm:h-6 sm:w-6 "
    },
    scrollContainer: {
      base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
      snap: "snap-x"
    }
  };

  return (
    <div className=" mb-16 mt-44  rounded-none">
      <div className="_slider">
        <Carousel theme={customTheme}>
          
          <div
            style={divStyle1}
            className="item-1 flex h-full items-center justify-center rounded-none _item_1 justify-start"
          >
            <div className="flex flex-col justify-start mx-40 _item_inside">
              <img src={linkIcon} className="size-12" alt="" />
              <h4 className=" flex">
                Explore Our Tablets</h4>
              <p className="text-left my-2">
                Discover our diverse range of innovative tablets designed to
                enhance your learning and clinical practice with the latest
                technology.
              </p>
              <Link to={'store/tabs'} className="flex items-start justify-start">
              <button className="_yellow_button _button">
                More about 
              </button>
              </Link>
            </div>
          </div>
          <div
            style={divStyle2}
            className="item-2 flex h-full items-center justify-center rounded-none _item_1 justify-start"
          >
            <div className="flex flex-col justify-start mx-40 _item_inside">
              <img src={linkIcon} className="size-12" alt="" />
              <h4 className="text-center md:text-left">
              Explore Our Tab Accessories</h4>
              <p className="text-left my-2">
              Our tab accessories are designed to enhance your educational and clinical activities, offering seamless integration with the latest technology for improved efficiency and performance.
              </p>
              <Link to={'store/tabaccessories'} className="flex items-start justify-start">
              <button className="_yellow_button _button">
                More about 
              </button>
              </Link>
            </div>
          </div>
          <div
            style={divStyle3}
            className="item-3 flex h-full items-center justify-center rounded-none _item_1 justify-start"
          >
            <div className="flex flex-col justify-start mx-40 _item_inside">
              <img src={linkIcon} className="size-12" alt="" />
              <h4 className=" flex">
              Explore Our Stethoscope Accessories</h4>
              <p className="text-left my-2">
              Discover our range of stethoscope accessories, crafted to ensure precision and comfort, helping you maintain the highest standards in clinical practice.
              </p>
              <Link to={'store/stethaccessories'} className="flex items-start justify-start">
              <button className="_yellow_button _button">
                More about 
              </button>
              </Link>
            </div>
          </div>
         
        </Carousel>
      </div>
    </div>
  );
}

export default HeroExclusive;
