import React, { useState, useEffect } from "react";
import Loading from "../../components/loadingPulse/Loading";
import { Link } from "react-router-dom";
import { CgCloseR } from "react-icons/cg";
const apiBase = process.env.REACT_APP_API;

const Cart = ({ setCartItemCount }) => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "MEDtools.lk | Cart";
  }, []);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        // Retrieve and parse cart items from localStorage
        const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];
        const subProductIds = storedCart.map((item) => item.sub_product_id); // Extract sub_product_id

        // Send sub_product_id array to the backend
        const response = await fetch(`${apiBase}/store/cart`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subIds: subProductIds }),
        });

        const data = await response.json();
        if (response.ok) {
          // Parse localData from localStorage and ensure it's an array
          const localData = JSON.parse(localStorage.getItem("cartItems")) || [];

          // Map over the data received from the backend
          const updatedData = data.map((item) => {
            // Find the corresponding item in localData based on sub_product_id
            const matchingLocalItem = localData.find(
              (localItem) => localItem.sub_product_id === item.sub_product_id
            );

            if (matchingLocalItem) {
              return {
                ...item,
                quantity: Number(matchingLocalItem.quantity), // Update quantity from localData
              };
            }

            return item; // Return original item if no match is found
          });

          // Log the merged data
          setCartItems(updatedData); // Set the updated data in state
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchItem();
  }, []);

  const handleQuantityChange = (sub_product_id, quantity) => {
    if (isNaN(quantity) || quantity < 1) {
      return; // Prevent setting invalid quantities
    }

    // Retrieve the current cart items from localStorage and parse them
    const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];

    // Update the quantity for the matching sub_product_id
    const updatedCart = storedCart.map((item) => {
      if (item.sub_product_id === sub_product_id) {
        return { ...item, quantity: String(quantity) }; // Update quantity, ensuring it's a string
      }
      return item;
    });

    // Save the updated cart back to localStorage
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));

    // Update the cartItems state to trigger a re-render
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.sub_product_id === sub_product_id ? { ...item, quantity } : item
      )
    );
  };

  const calculateTotalPrice = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const handleRemoveItem = (sub_product_id) => {
    // Retrieve the current cart items from localStorage and parse them
    const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];

    // Filter out the item with the matching sub_product_id
    const updatedCart = storedCart.filter(
      (item) => item.sub_product_id !== sub_product_id
    );

    // Save the updated cart back to localStorage
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));

    // Update the cartItems state to remove the item
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.sub_product_id !== sub_product_id)
    );
  };

  console.log(cartItems.length);
  return (
    <div>
      <div className="max-w-6xl mx-auto p-4 pt-52">
        <h1 className="text-3xl font-bold mb-4">Cart</h1>
        <table className="w-full border-collapse border border-gray-200">
          <thead>
            <tr className="text-myDark">
              <th className="px-4 py-2">Product</th>
              <th className="hidden md:block px-4 py-2">Price</th>
              <th className="px-4 py-2">Quantity</th>
              <th className="hidden lg:block px-4 py-2">Total Price</th>
              <th className="px-4 py-2"></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Loading />
            ) : (
              cartItems.map((item) => (
                <tr key={item.id} className="">
                  <td className="px-1 md:px-4 truncate py-2 text-myDark font-semibold hover:shadow-sm bg-myLight hover:bg-myDim hover:bg-opacity-10  rounded-lg">
                    <Link to={`/item/${item.product_id}`}>
                      <div className="text-left truncate">
                        <img src={item.photo_url} alt="" />
                        <div>
                          <div>
                            {item.title}
                            <br />
                            <p className="font-base text-xs text-myDim">
                              {item.cat_1}
                            </p>
                          </div>
                          <p className="block md:hidden text-lg py-4">
                            Rs.{item.price}.00
                          </p>
                        </div>
                      </div>
                    </Link>
                  </td>
                  <td className="hidden md:block px-4 py-2 text-myDark font-semibold ">
                    Rs.{item.price}.00
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) =>
                        handleQuantityChange(
                          item.sub_product_id,
                          parseInt(e.target.value, 10)
                        )
                      }
                      className="w-12 text-center rounded  focus:ring-1 focus:ring-myDim w-16 md:w-24 text-myDim font-semibold"
                    />
                  </td>
                  <td className=" hidden lg:block px-4 py-2 text-myDark font-semibold">
                    Rs.{(item.price * item.quantity).toFixed(2)}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => {
                        handleRemoveItem(item.sub_product_id);
                        setCartItemCount((c) => c - 1);
                      }}
                    >
                      <CgCloseR className="md:hidden text-myRed size-6" />
                    </button>
                    <button
                      onClick={() => {
                        handleRemoveItem(item.sub_product_id);
                        setCartItemCount((c) => c - 1);
                      }}
                      className=" hidden md:block text-white bg-myRed p-2 rounded-full hover:bg-opacity-90 font-semibold transition duration-300"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {cartItems.length == 0 && !loading && (
          <div className="text-center py-10  rounded-lg shadow-sm bg-myLight border block mt-5">
            <p className="text-xl font-semibold text-gray-700 mb-4">
              Your cart is empty
            </p>
            <h3 className="text-lg text-myDim  hover:text-blue-700">
              <Link to={"/store"}>vist store</Link>
            </h3>
          </div>
        )}
        <p className="text-lg font-bold mb-2 mt-5 text-right text-myDark font-semibold bg-myDim bg-opacity-5 rounded-md p-2">
          Total Price: Rs.{calculateTotalPrice().toFixed(2)}
        </p>
        <div className="flex flex-row justify-end py-3">
          <Link to={"/cashondellivery"}>
            <button
              disabled={
                cartItems.length == 0 && !loading && calculateTotalPrice() < 30
              }
              className="bg-myRed hover:bg-opacity-80 py-2 px-4 mx-1 rounded-full text-white"
            >
              Cash on delivery
            </button>
          </Link>
          <Link to={"/payment"}>
            {" "}
            <button
              disabled={cartItems.length == 0 && !loading}
              className="bg-myDim hover:bg-opacity-80 py-2 px-4  mx-1 rounded-full text-white"
            >
              Online Payment
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cart;
