import React from 'react';
import './itemCard.css';
import StarRating from './StarRating';
import { Link } from 'react-router-dom';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';

const ItemCard = ({ product_id, photo_url, title, original_price, discount_price, isInWishlist, onWishlistToggle }) => {
  return (
    <div className="shadow-md min-w-36 min-[450px]:w-48 sm:min-w-60 w-auto rounded-lg bg-white bg-opacity-75">
      <Link to={`/item/${product_id}`}>
        <div
          className="w-full rounded-t-lg h-28 sm:h-48 bg-cover shadow-sm"
          style={{ backgroundImage: `url(${photo_url})` }}
        ></div>

        <div className='flex flex-col items-start p-3 w-full'>
          <h3 className="text-left font-semibold text-myDark text-sm sm:text-lg w-full truncate">{title}</h3>
          <div className='flex flex-row space-x-2 lg:space-x-4'>
            <p className='text-sm sm:text-xl md:text-2xl font-semibold text-myRed'>Rs.{discount_price}.00</p>
          </div>
          <div className='flex flex-row text-xs md:text-base space-x-2 lg:space-x-4'>
            <p className='_discount line-through text-myDim'>Rs.{original_price}.00</p>
          </div>
          <div className='w-full flex items-end mt-2 justify-end'>
            <StarRating rating="3.5" />
          </div>
        </div>
      </Link>
      {/* Wishlist Button */}
      <button
        onClick={onWishlistToggle}
        className="flex justify-center items-center py-2 px-4 bg-myDim text-white w-full rounded-b-lg hover:bg-opacity-80 transition truncate"
      >
        {isInWishlist ? (
          <AiFillHeart className="text-red-600  size-5 sm:size-6 object-cover mr-2" />
        ) : (
          <AiOutlineHeart className="text-white size-5 sm:size-6 mr-2" />
        )}
        <p className='text-sm sm:text-base truncate'>{isInWishlist ? "Remove" : "Add to Wishlist"}</p>
      </button>
    </div>
  );
};

export default ItemCard;
